<footer class="footer">
  <img class="footer-logo" [style.width.px]="150" [src]="logos.usual" alt="Nabix"/>
  <p class="footer-info">
    Made by &copy; NABIX, {{ year }} <a href="https://nabix.ru" target="_blank" class="download-title">NABIX.ru</a>
  </p>
  <p [style.margin-top.px]="8">
    <small>
      <button class="button unfilled" [style.padding-left.px]="0" (click)="onLogout()">Выйти</button>

  <!--    <ng-container *ngIf="isUser1c || isClearQueue">
        <a
          [style.opacity]="loading && .7"
          [style.margin-left.px]="30"
          class="--underline"
          (click)="test1c(0)"
        >
          ОЧИСТИТЬ ОЧЕРЕДЬ
        </a>
        <a
          [style.opacity]="loading && .7"
          [style.margin-left.px]="30"
          class="--underline"
          (click)="test1c(2)"
        >
          ПРОВЕРИТЬ ОЧЕРЕДЬ
        </a>
        <span>Элементов в очереди: {{ countQueue}}</span>
      </ng-container>
      <a *ngIf="isUser1c"
         [style.opacity]="loading && .7"
         [style.margin-left.px]="30"
         class="--underline"
         (click)="test1c(1)"
      >
        ТЕСТОВЫЙ ПРЕДЧЕК
      </a>


      <a
        *ngIf="showQueue1CLink"
        [style.margin-left.px]="30"
        [style.opacity]="loading && .7"
        (click)="goto('queue-1c')"
        class="--underline"
      >
        ОЧЕРЕДЬ МОДУЛЯ 1С
      </a>

      <a
        *ngIf="showQueueKKMLink"
        [style.margin-left.px]="30"
        [style.opacity]="loading && .7"
        (click)="goto('cashbox-queue')"
        class="--underline"
      >
        ОЧЕРЕДЬ ЧЕКОВ ККМ
      </a>

      <a
        *ngIf="(isAdminNabix$ | async) || (isSuperAdminNabix$ | async)"
        [style.margin-left.px]="30"
        [style.opacity]="loading && .7"
        (click)="goto('logger/info')"
        class="--underline"
      >
        ЛОГИ
      </a> -->

    </small><br>
  </p>
</footer>
