import { Component, OnInit, OnDestroy, AfterViewInit, Input, Output, EventEmitter } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { Subject, Subscription } from "rxjs";
import { fadeInOut, fadeInOutUp, fadeInOutUpStagged } from "src/core/animation";
import { rnd } from "src/core/helpers/data.helper";
import { BehaviorService } from "src/services/behavior.service";

@Component({
  selector: 'app-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
  animations: [fadeInOut, fadeInOutUp, fadeInOutUpStagged]
})
export class CheckboxComponent implements OnInit, OnDestroy, AfterViewInit {
  protected ngUnsubscribe: Subject<void> = new Subject<void>();
  @Input() label: string;
  @Input() nowrap = false;
  @Input() idInput?: string;
  @Input() name: '';
  @Input() wrapperClass = '';
  @Input() hintBottom? = '';
  @Input() parentForm?: FormGroup;
  @Input() state = false;
  @Input() readonly = false;
  @Input() hint = '';
  @Input() hintShift = 0;
  @Output() stateChange = new EventEmitter<boolean>();
  @Input() invalid = null;
  @Input() controlInvalid = null;

  subs = new Subscription();

  id: string;
  wrapperId = `input_wrapper_${rnd(10)}`;
  constructor(
    private behavior: BehaviorService
  ) { }

  get isReactive(): boolean {
    return !!this.parentForm;
  }

  get currentControl(): any {
    return this.parentForm && this.parentForm.controls[this.name];
  }

  get errorCount(): number {
    return !!this.currentControl && !!this.currentControl.errors ? Object.keys(this.currentControl.errors).length : 0;
  }

  get apiErrorCount(): number {
    return !!this.currentControl && !!this.currentControl.apiErrors ? this.currentControl.apiErrors.length : 0;
  }

  get setInvalidClass(): boolean {
    return !!this.invalid || ((this.apiErrorCount > 0 || this.errorCount > 0) && this.controlInvalid);
  }

  ngOnInit(): void {
    if (!!this.hint) {
      this.behavior.inputHintDetectStatus.next(this.wrapperId);
    }
    this.wrapperClass = `${this.wrapperClass} ${!!this.hint ? 'input-wrapper-hint' : ''}`;

    if (this.idInput) {
      this.id = this.idInput + this.name;
    } else {
      this.id = `input_${rnd(10)}`;
    }
  }

  ngAfterViewInit(): void {
    this.checkHint();
  }

  checkHint(): void {
    if (!!this.hint) {
      this.behavior.inputHintDetectStatus.next(this.wrapperId);
    }
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  dataChange(): void {
    if (this.readonly) {
      return;
    }
    this.stateChange.emit(this.isReactive ? this.parentForm.value[this.name] : this.state)
  }
}
