import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subject} from "rxjs";
import {Store} from "@ngxs/store";
import {Navigate} from "@ngxs/router-plugin";
import {ICards} from "../../../../core/models/common.models";

@Component({
  selector: 'app-main-operations',
  templateUrl: './main-operations.component.html',
  styleUrls: ['./main-operations.component.scss']
})
export class MainOperationsComponent implements OnInit, OnDestroy {
  protected ngUnsubscribe: Subject<void> = new Subject<void>();

  showIndex = 1

  cards: ICards[] = [{
    img: 'uni-logo.svg',
    title: 'Отправить перевод',
    goto: '/operation/transfer'
  }, {
    img: 'cards.svg',
    title: 'Пополнение карт',
    goto: '/operation/tcs',
    wrapper: 'p-relative',
    imgClass: 'fav-cards',
    titleClass: 'mt-140',
    operations: [
      // {
      //   img: 'uni-logo.svg',
      //   title: '',
      //   goto: '/operation/cash-unistream-card'
      // },
      {
        img: 'tinkoff-logo.svg',
        title: '',
        goto: '/operation/tcs'
      },
      {
        img: 'corty-milli.svg',
        title: '',
        goto: '/operation/corty-milli'
      },
      {
        img: 'uzcard.svg',
        title: '',
        goto: '/operation/uzcard'
      },
      {
        img: 'union-pay.svg',
        title: '',
        goto: '/operation/union-pay-card'
      }]
  }, {
    img: 'patent-logo.svg',
    title: 'Оплата ЖКУ',
    goto: '/operation/utilities'
  },
    {
      img: 'brs-logo.svg',
      title: 'Погашение кредита',
      goto: '/operation/brsaccount'
    },
    // {
    //   img: 'uni-logo.svg',
    //   title: 'Пополнение карты',
    //   goto: '/operation/cash-unistream-card'
    // },
    // {
    //   img: 'yandex-money.svg',
    //   title: 'Яндекс Деньги',
    //   goto: '/operation/yandex-money',
    //   opers: [{
    //     img: 'yandex-money.svg',
    //     title: 'Пополнение кошелька',
    //     goto: '/operation/yandex-money'
    //   },
    //   {
    //     img: 'yandex-money.svg',
    //     title: 'Идентификация',
    //     goto: '/operation/yandex-money-identification'
    //   },
    //   {
    //     img: 'yandex-money.svg',
    //     title: 'Яндекс Деньги СНГ',
    //     goto: '/operation/yandex-money-sng'
    //   },
    //   {
    //     img: 'yandex-money.svg',
    //     title: 'Оплата заказа в интернет-магазине',
    //     goto: '/operation/yandex-money-shops'
    //   }]
    // }
  ]

  constructor(
    private store: Store
  ) { }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  trackByFn(i, item) {
    return item.id
  }

  goto(url: string): void {
    this.store.dispatch(new Navigate([`/${url}`]))
  }
}
