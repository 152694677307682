import {Component, OnDestroy, OnInit} from '@angular/core';
import {Observable, Subject, Subscription} from "rxjs";
import {Select} from "@ngxs/store";
import {User, UserState} from "../../../store/state/user.state";

@Component({
  selector: 'app-personal-area',
  templateUrl: './personal-area.component.html',
  styleUrls: ['./personal-area.component.scss']
})
export class PersonalAreaComponent implements OnInit, OnDestroy {
  protected ngUnsubscribe: Subject<void> = new Subject<void>();
  @Select(UserState.isMobile) isMobile$: Observable<boolean>;
  subs = new Subscription();
  isMobile = false;

  constructor() { }

  ngOnInit(): void {
    this.subs.add(this.isMobile$.subscribe(isMobile => { this.isMobile = isMobile }));
  }

  ngOnDestroy() {
    this.subs.unsubscribe()
    this.ngUnsubscribe.next()
    this.ngUnsubscribe.complete()
  }
}
