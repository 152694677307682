import {
  ApiClient,
  Client,
  IUserRestorePasswordRequest,
  UserForgotPasswordRequest,
  UserLoginRequest, UserRestorePasswordRequest
} from '../../ApiClient';
import { Component, OnInit, OnDestroy } from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators as v,
  Validators,
} from '@angular/forms';
import { Navigate } from '@ngxs/router-plugin';
import { Select, Store } from '@ngxs/store';
import { Subject, Observable, Subscription } from 'rxjs';
import { UserState } from 'src/store/state/user.state';
import { Location } from '@angular/common';
import {NotifyService} from "../../../services/notify.service";
import {errorMessages} from "../../../constants/errors";
import {LoginProviderEnum} from "../../../enum/login-provider.enum";
import {debounceTime, distinctUntilChanged, filter, map, switchMap, takeUntil} from "rxjs/operators";
import {isExpectedError, mapInvalidFields} from "../../../core/helpers/data.helper";
import {HomeRedirect, SetToken, SetUser} from "../../../store/actions/user.actions";
import {validateEmail} from "../../../core/validators";
import {messages} from "../../../constants/messages";
import {ActivatedRoute} from "@angular/router";
import {BehaviorService} from "../../../services/behavior.service";

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss']
})
export class AuthComponent implements OnInit, OnDestroy {
  protected ngUnsubscribe: Subject<void> = new Subject<void>();
  @Select(UserState.isAuthorized) isAuthorized$: Observable<boolean>;
  subs = new Subscription();

  authGroup: FormGroup;
  phone: FormControl;
  email: FormControl;
  password: FormControl;
  newPassword: FormControl;
  repeatNewPassword: FormControl;
  rememberMe: FormControl;

  title = '';
  url = '';

  isLogin = true;
  isRestore = false;
  isForgot = false;

  isSubmit = false;

  isForgotByPhone = true;
  isUrlCorrect = false;

  constructor(
    private store: Store,
    private apiClient: ApiClient,
    private location: Location,
    private notify: NotifyService,
    private route: ActivatedRoute,
    private behavior: BehaviorService
  ) {
    this.route.params
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(params => {
        if (!!params && !!params.url) {
          this.url = String(params.url);
          this.checkAccess();
        } else {
          this.goto('login');
        }
      })
  }

  get isLoginCorrect(): boolean {
    return !!this.authGroup &&
      !this.authGroup.controls.Phone.errors &&
      !this.authGroup.controls.Password.errors;
  }

  get isForgotCorrect(): boolean {
    return !!this.authGroup &&
      !this.authGroup.controls.Phone.errors;
  }

  get isRestoreCorrect(): boolean {
    return !!this.authGroup &&
      !this.authGroup.controls.Phone.errors &&
      !this.authGroup.controls.Email.errors &&
      !this.authGroup.controls.NewPassword.errors &&
      !this.authGroup.controls.RepeatNewPassword.errors &&
      this.isNewPasswordCompared;
  }

  get isNewPasswordCompared(): boolean {
    return !!this.authGroup &&
      (!!this.authGroup.value.NewPassword && !!this.authGroup.value.RepeatNewPassword &&
        this.authGroup.value.NewPassword === this.authGroup.value.RepeatNewPassword ||
        !this.authGroup.value.NewPassword && !this.authGroup.value.RepeatNewPassword)
  }

  ngOnInit(): void {
    this.subs.add(
      this.isAuthorized$.subscribe((isAuthorized) => {
        if (isAuthorized) {
          this.store.dispatch(new Navigate(['/home']));
        }
      }),
    );
    this.createAuthFormControls();
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  changeForgotType(): void {
    this.isForgotByPhone = !this.isForgotByPhone;
  }

  initTitle(url: string): string {
    this.setDefaultState();
    this.isSubmit = false;
    if (url.includes('login')) {
      this.initState(true, false, false);
      return 'Добро пожаловать в NABIX Partner!';
    }
    if (url.includes('restore')) {
      this.initState(false, true, false);
      return 'Форма восстановления пароля';
    }
    if (url.includes('forgot')) {
      this.initState(false, false, true);
      return 'Восстановите свой пароль';
    }
    this.initState(false, false, false);
    return '';
  }

  goto(url: string): void {
    const path = `auth/${url}`;
    this.title = this.initTitle(path);
    this.location.replaceState(path);
    this.setDefaultState();
  }

  initState(
    login: boolean,
    restore: boolean,
    forgot: boolean
  ): void {
    this.isLogin = login;
    this.isRestore = restore;
    this.isForgot = forgot;
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  createAuthFormControls(): void {
    this.phone = new FormControl(null, [v.required]);
    this.email = new FormControl(null, [v.required, validateEmail]);
    this.password = new FormControl(null, [v.required]);
    this.newPassword = new FormControl(null);
    this.repeatNewPassword = new FormControl(null);
    this.rememberMe = new FormControl(false);
    this.authGroup = new FormGroup({
      Phone: this.phone,
      Email: this.email,
      Password: this.password,
      NewPassword: this.newPassword,
      RepeatNewPassword: this.repeatNewPassword,
      RememberMe: this.rememberMe,
    });
    this.setDefaultValidators();
    this.title = this.initTitle(this.location.path());

    this.authGroup.valueChanges
      .pipe(
        debounceTime(100),
        filter((d: any) => d.NewPassword && d.RepeatNewPassword),
        map((d) => ({
          newPassword: d.NewPassword,
          repeatNewPassword: d.RepeatNewPassword
        })),
        distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe((d) => {
        this.authGroup.controls.NewPassword.setErrors(!!d.newPassword && !!d.repeatNewPassword &&
        d.newPassword !== d.repeatNewPassword ? {pwdDontMatch : true} : null);
      });

    this.behavior.initCenterStatus.next(true);
  }

  setDefaultValidators(): void {
    this.authGroup.controls.Phone.setValidators([v.required]);
    this.authGroup.controls.Email.setValidators([v.required, validateEmail()]);
    this.authGroup.controls.Password.setValidators([v.required]);
    this.authGroup.controls.NewPassword.setValidators(null);
    this.authGroup.controls.RepeatNewPassword.setValidators(null);
    this.authGroup.controls.RememberMe.setValidators(null);
  }

  setDefaultState(): void {
    if (!this.authGroup) {
      return
    }
    this.authGroup.controls.Phone.setValue(null);
    this.authGroup.controls.Email.setValue(null);
    this.authGroup.controls.Password.setValue(null);
    this.authGroup.controls.NewPassword.setValue(null);
    this.authGroup.controls.RepeatNewPassword.setValue(null);
    this.authGroup.controls.RememberMe.setValue(false);
  }

  onLogin(): void {
    this.isSubmit = true;
    if (!this.isLoginCorrect) {
      this.notify.error(errorMessages.fillAllFieldsToContinue);
      return;
    }

    this.apiClient.auth_Login({
      phone: `+7${this.authGroup.value.Phone}`,
      provider: String(LoginProviderEnum.MTSPhone),
      password: this.authGroup.value.Password,
      remember: this.authGroup.value.RememberMe
    } as UserLoginRequest)
      .pipe(
        takeUntil(this.ngUnsubscribe),
        map((token) => this.store.dispatch(new SetToken(token))),
        switchMap((token) => this.apiClient.users_GetCurrentUser()),
      )
      .subscribe(
        (user) => {
          this.store.dispatch(new SetUser(user.user));
        },
        (error) => {
          if (isExpectedError(error)) {
            this.authGroup = mapInvalidFields(this.authGroup, error.invalidFields);
            this.notify.error(error.message);
          } else {
            this.notify.error(errorMessages.serverRequestError);
          }
          console.error(error);
        },
      );
  }

  onForgot(): void {
    this.isSubmit = true;
    if (!this.isForgotCorrect) {
      this.notify.error(errorMessages.fillAllFieldsToContinue);
      return;
    }

    this.apiClient.auth_ForgotPassword({
      login: this.isForgotByPhone ? `+7${this.authGroup.value.Phone}` : this.authGroup.value.Email
    } as UserForgotPasswordRequest)
      .subscribe(
        (data) => {
          this.notify.success(messages.passwordSendToEmail);
          this.goto('login');
        },
        (error) => {
          if (isExpectedError(error)) {
            this.authGroup = mapInvalidFields(this.authGroup, error.invalidFields);
            this.notify.error(error.message);
          } else {
            this.notify.error(errorMessages.serverRequestError);
          }
          console.error(error);
        },
      );
  }

  onRestore(): void {
    this.isSubmit = true;
    if (!this.isRestoreCorrect) {
      this.notify.error(errorMessages.fillAllFieldsToContinue);
      return;
    }

    const payload: IUserRestorePasswordRequest = {
      phone: `+7${this.authGroup.value.Phone}`,
      email: this.authGroup.value.Email,
      password: this.authGroup.value.NewPassword,
      repeatPassword: this.authGroup.value.RepeatNewPassword,
      url: this.url
    };

    this.apiClient.auth_RestorePassword(payload as UserRestorePasswordRequest)
      .subscribe(
        (data) => {
          this.notify.success(messages.passwordChanged)
          this.goto('login');
        },
        (error) => {
          if (isExpectedError(error)) {
            this.authGroup = mapInvalidFields(this.authGroup, error.invalidFields);
            this.notify.error(error.message);
          } else {
            this.notify.error(errorMessages.serverRequestError);
          }
          console.error(error);
        },
      );
  }

  checkAccess(): void {
    this.isUrlCorrect = false;
    this.apiClient.auth_ResetPasswordCheckAccess(this.url)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(data => {
        this.isUrlCorrect = true;
      }, error => {
        this.goto('login');
        this.notify.error(error.message)
      })
  }
}
