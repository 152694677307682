import {IPaginatorRequest} from "../../app/ApiClient";

export function CheckPaginator(paginator: IPaginatorRequest) {
  if (paginator == null) {
    paginator = createPaginator(5)
  }
  if (paginator.page < 1) {
    paginator.page = 1
  }
  if (paginator.take < 1) {
    paginator.take = 1
  }
  return paginator
}

export function createPaginator(take: number): IPaginatorRequest{
  return {
    page: 1,
    take,
    isFull: false
  }
}

export function fullPaginator(): IPaginatorRequest{
  return {
    page: 1,
    take: 5,
    isFull: true
  }
}
