import {
  IDataSelectedHelperOfInteger,
  IDataSelectedHelperOfString,
  IMtsService, IUserAvailableDataRequest
} from '../../app/ApiClient';
import {UserAvailableDataEnum} from "../../enum/user-available-data";
export class SetUserRoles {
  static type = '[Dictionary] SetUserRoles'
  constructor(public data: IDataSelectedHelperOfInteger[]) {}
}
export class SetTimeZones {
  static type = '[Dictionary] SetTimeZones'
  constructor(public data: IDataSelectedHelperOfInteger[]) {}
}
export class SetCountries {
  static type = '[Dictionary] SetCountries'
  constructor(public data: IDataSelectedHelperOfString[]) {}
}
export class SetCurrencies {
  static type = '[Dictionary] SetCurrencies'
  constructor(public data: IDataSelectedHelperOfString[]) {}
}
export class SetServicePoints {
  static type = '[Dictionary] SetServicePoints'
  constructor(public data: IDataSelectedHelperOfString[]) {}
}
export class SetServices {
  static type = '[Dictionary] SetServices'
  constructor(public data: IMtsService[]) {}
}
export class SetAvailableUsers {
  static type = '[Dictionary] SetAvailableUsers'
  constructor(public data: IDataSelectedHelperOfString[]) {}
}
export class GetAvailableData {
  static type = '[Dictionary] SetAvailableUsers'
  constructor(public data: IUserAvailableDataRequest) {}
}
export class ResetDictionaries {
  static type = '[Dictionary] ResetDictionaries'
}
