<div class="error-wrapper" (click)="showControlErrors()" [style.height.px]="wrapperErrorHeight" [ngClass]="{'inline-wrap': nowrap}">
  <div class="p-absolute" *ngIf="(wrapperErrorHeight > 0)" [@fadeInOutUp]>
    <div class="error-item" *ngIf="currentControl.hasError('email')">Неверный формат электронной почты</div>
    <div class="error-item" *ngIf="currentControl.hasError('pwdDontMatch')">Пароли не совпадают</div>
    <div class="error-item" *ngIf="currentControl.hasError('login')">Неверный формат логина</div>
    <div class="error-item" *ngIf="currentControl.hasError('validateRus')">Используйте кириллицу</div>
    <div class="error-item" *ngIf="currentControl.hasError('atLeastOnceOperationType')">Выберите хотя бы один тип операций</div>
    <div class="error-item" *ngIf="currentControl.hasError('validateEng')">Используйте латиницу</div>
    <div class="error-item" *ngIf="currentControl.hasError('confrimPhone')">Подтвердите телефон</div>
    <div class="error-item" *ngIf="currentControl.hasError('FIO')">Неверный формат</div>
    <div class="error-item" *ngIf="currentControl.hasError('matDatepickerParse')">Неверная дата</div>
    <div class="error-item" *ngIf="currentControl.hasError('confrimEmail')">Подтвердите электронную почту</div>
    <div class="error-item" *ngIf="currentControl.hasError('required')">Обязательное поле</div>
    <div class="error-item" *ngIf="currentControl.hasError('CapitalLetter')">Заглавная буква только в начале слова</div>
    <div class="error-item" *ngIf="currentControl.hasError('NonDigit')">Недопустим ввод цифр</div>
    <div class="error-item" *ngIf="currentControl.hasError('NonSymbol')">Допустим ввод только символов "пробел" и "-". Остальные запрещены</div>
    <div class="error-item" *ngIf="currentControl.hasError('validateRadioPress')">Выберите выше</div>
    <div class="error-item" *ngIf="currentControl.hasError('phoneConfirmNeed')">Подтвердите номер телефона</div>
    <div class="error-item" *ngIf="currentControl.hasError('onlyTrue')">Поставьте галочку</div>
    <div class="error-item" *ngIf="currentControl.hasError('validateAdultMin')">Укажите корректную дату</div>
    <div class="error-item" *ngIf="currentControl.hasError('validateAdult')">Вам должно быть больше 18 лет</div>
    <div class="error-item" *ngIf="currentControl.hasError('matDatepickerMin') || currentControl.hasError('matDatepickerMax')">Некорректная дата</div>
    <div class="error-item" *ngIf="currentControl.hasError('mask')">Укажите данные в формате {{currentControl.errors.mask.requiredMask}}</div>
    <div class="error-item" *ngIf="!!currentControl.errors && currentControl.errors.minLength">Введите не менее {{currentControl.errors.minLength.requiredLength}} цифр</div>
    <div class="error-item" *ngIf="!!currentControl.errors && currentControl.errors.maxLength">Введите не более {{currentControl.errors.maxLength.requiredLength}} цифр</div>
    <ng-container *ngIf="apiErrorCount > 0">
      <div class="error-item" *ngFor="let apiError of currentControl.apiErrors; let i=index">{{apiError}}</div>
    </ng-container>
  </div>
</div>
<!-- <h1>{{fromComponent}}</h1> -->
<!-- <h1 (click)="showControlErrors()">{{showErrors}}</h1>-->
