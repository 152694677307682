import {Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import {ApiClient, IUserAddOrUpdateRequest, IUserResponse, UserAddOrUpdateRequest} from "../../../../ApiClient";
import {UserState} from "../../../../../store/state/user.state";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {RoleProvider, RoleProviderRU} from "../../../../../enum/role-provider.enum";
import {fadeIn, fadeInOut, fadeInOutStagged} from "../../../../../core/animation";
import {Select, Store} from "@ngxs/store";
import {Observable, Subject, Subscription} from "rxjs";
import {takeUntil} from "rxjs/operators";
import {NotifyService} from "../../../../../services/notify.service";
import {errorMessages} from "../../../../../constants/errors";
import {BehaviorService} from "../../../../../services/behavior.service";
import {DictionaryState} from "../../../../../store/state/dictionary.state";
import {IDataSelectedHelper} from "../../../../../core/models/common.models";
import {getSelectId, getUserRole, isExpectedError, mapInvalidFields} from "../../../../../core/helpers/data.helper";
import {messages} from "../../../../../constants/messages";

@Component({
  selector: 'app-users-modal',
  templateUrl: './users-modal.component.html',
  styleUrls: ['./users-modal.component.scss'],
  animations: [fadeInOutStagged, fadeInOut, fadeIn]
})
export class UsersModalComponent implements OnInit, OnDestroy {
  protected ngUnsubscribe: Subject<void> = new Subject<void>();
  @Output() modalClosed = new EventEmitter<boolean>();
  subs = new Subscription();
  @Select(UserState.getUser) user$: Observable<IUserResponse>;
  @Select(DictionaryState.getUserRoles) availableUserRoles$: Observable<IDataSelectedHelper[]>;
  @Select(DictionaryState.getTimeZones) availableTimeZones$: Observable<IDataSelectedHelper[]>;
  @Select(UserState.isAdministratorNabix) isAdministratorNabix$: Observable<boolean>;
  @Select(UserState.isSuperAdministratorNabix) isSuperAdministratorNabix$: Observable<boolean>;
  visible = false;

  isAdministratorNabix = false;
  isSuperAdministratorNabix = false;

  success = false;
  message = '';
  login = '';

  typeWindow = 0;

  availableUserRoles: IDataSelectedHelper[];
  availableTimeZones: IDataSelectedHelper[];
  user: IUserResponse;
  currentUser: IUserResponse;

  userForm: FormGroup;
  id: FormControl;
  lastName: FormControl;
  firstName: FormControl;
  patronymic: FormControl;
  phone: FormControl;
  email: FormControl;
  isBlocked: FormControl;
  role: FormControl;
  timeZoneId: FormControl;
  inn: FormControl;

  submitted = false;

  constructor(
    private behavior: BehaviorService,
    private store: Store,
    private notify: NotifyService,
    private apiClient: ApiClient
  ) { }

  ngOnInit() {
    this.subs.add(this.isAdministratorNabix$.subscribe(user => {
      this.isAdministratorNabix = user;
    }));
    this.subs.add(this.isSuperAdministratorNabix$.subscribe(user => {
      this.isSuperAdministratorNabix = user;
    }));
    this.subs.add(this.user$.subscribe(user => {
      this.currentUser = user;
    }));
    this.subs.add(this.availableUserRoles$.subscribe(availableUserRoles => {
      this.availableUserRoles = availableUserRoles;
    }));
    this.subs.add(this.availableTimeZones$.subscribe(availableTimeZones => {
      this.availableTimeZones = availableTimeZones;
    }));
    this.createFormControl();
  }

  ngOnDestroy() {
    this.subs.unsubscribe()
    this.ngUnsubscribe.next()
    this.ngUnsubscribe.complete()
  }
    createFormControl(): void {
      if (this.userForm) {
        return
      }
      this.lastName = new FormControl('', [Validators.required, Validators.maxLength(50)])
      this.firstName = new FormControl('', [Validators.required, Validators.maxLength(50)])
      this.patronymic = new FormControl('', [Validators.maxLength(50)])
      this.inn = new FormControl('', [Validators.minLength(10), Validators.maxLength(12)])
      this.id = new FormControl(null)
      this.phone = new FormControl('', [Validators.required])
      this.email = new FormControl('', [Validators.required])
      this.role = new FormControl(!!this.availableUserRoles && this.availableUserRoles.length > 0 ?
        this.availableUserRoles[this.availableUserRoles.length - 1].label : '', [Validators.required])
      this.timeZoneId = new FormControl(!!this.availableTimeZones && !!this.availableTimeZones[0] ?
        this.availableTimeZones[0].label : '', [Validators.required])
      this.isBlocked = new FormControl(false)

      this.userForm = new FormGroup({
        LastName: this.lastName,
        FirstName: this.firstName,
        Patronymic: this.patronymic,
        Id: this.id,
        Phone: this.phone,
        Email: this.email,
        IsBlocked: this.isBlocked,
        Role: this.role,
        TimeZoneId: this.timeZoneId,
        Inn: this.inn
      })
    }

    checkFormControls(): void {
      if (this.user) {
        this.userForm.controls.LastName.setValue(this.user.lastName);
        this.userForm.controls.FirstName.setValue(this.user.firstName);
        this.userForm.controls.Patronymic.setValue(this.user.patronymic);
        this.userForm.controls.Inn.setValue(this.user.inn);
        this.userForm.controls.Id.setValue(this.user.id);
        this.userForm.controls.Phone.setValue(this.user.phone);
        this.userForm.controls.Email.setValue(this.user.email);
        this.userForm.controls.IsBlocked.setValue(this.user.isBlocked);
        const role = this.availableUserRoles.find(x => String(x.id) === String(this.user.roleName) ||
          String(x.label) === String(this.user.roleName) ||
          String(x.role) === String(this.user.roleName));
        this.userForm.controls.Role.setValue(role && role.label);
        const timeZoneId = this.availableTimeZones.find(x => String(x.id) === String(this.user.timeZoneId) ||
          String(x.label) === String(this.user.timeZoneId) ||
          String(x.role) === String(this.user.timeZoneId));
        this.userForm.controls.TimeZoneId.setValue(timeZoneId && timeZoneId.label);
      } else {
        this.userForm.controls.LastName.setValue('');
        this.userForm.controls.FirstName.setValue('');
        this.userForm.controls.Patronymic.setValue('');
        this.userForm.controls.Inn.setValue(null);
        this.userForm.controls.Id.setValue(null);
        this.userForm.controls.Phone.setValue(null);
        this.userForm.controls.Email.setValue('');
        this.userForm.controls.IsBlocked.setValue(false);
        this.userForm.controls.Role.setValue(!!this.availableUserRoles && this.availableUserRoles.length > 0 ?
          this.availableUserRoles[this.availableUserRoles.length - 1].label : '');
        this.userForm.controls.TimeZoneId.setValue(!!this.availableTimeZones && !!this.availableTimeZones[0] ?
          this.availableTimeZones[0].label : '');
      }
    }

    changeBlock(event: any): void {
    }

  arrOrUpdateUser(): void {
      this.submitted = true;
      if (this.userForm.invalid) {
        return;
      }
      const payload: IUserAddOrUpdateRequest = {
        id: !!this.user ? this.user.id : null,
        lastName: this.userForm.value.LastName,
        firstName: this.userForm.value.FirstName,
        patronymic: this.userForm.value.Patronymic,
        phone: this.userForm.value.Phone,
        email: this.userForm.value.Email,
        inn: this.userForm.value.Inn,
        isBlocked: this.userForm.value.IsBlocked,
        role: getUserRole(this.userForm.value.Role),
        timeZoneId: Number(getSelectId(this.userForm.value.TimeZoneId, this.availableTimeZones, false))
      };
      this.apiClient.users_AddOrUpdateUser(payload as UserAddOrUpdateRequest)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(data => {
            if (this.user) {
              this.notify.success(messages.userUpdated)
              this.toggleModal(null);
            } else {
              this.success = true
              this.message = data.message
              this.login = data.user.phone
              this.notify.success(messages.userCreated)
            }
            this.user = data.user
        },
          (error) => {
            if (isExpectedError(error)) {
              this.userForm = mapInvalidFields(this.userForm, error.invalidFields);
              this.notify.error(error.message);
            } else {
              this.notify.error(errorMessages.serverRequestError);
            }
            console.error(error);
          });
    }

    resetPasswordFromAdmin(): void {
      if (!this.user ||
        !this.isAdministratorNabix &&
        !this.isSuperAdministratorNabix) {
        return;
      }
      this.apiClient.auth_FromAdminResetPassword(this.user.id)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(data => {
            this.success = true;
            this.message = data.message;
            this.login = this.user.phone;
            this.notify.success(messages.passwordReset);
          },
          (error) => {
            if (isExpectedError(error)) {
              this.notify.error(error.message);
            } else {
              this.notify.error(errorMessages.serverRequestError);
            }
            console.error(error);
          });
    }

  toggleModal(currentUser: IUserResponse) {
    this.success = false;
    this.user = currentUser as IUserResponse;
    this.submitted = false;
    this.typeWindow = 0;
    this.modalClosed.emit(this.visible);
    this.visible = !this.visible;
    this.checkFormControls();
  }

  setDisabled(name: string, set: boolean): void {
    if (set) {
      this.userForm.controls[name].disable()
    } else {
      this.userForm.controls[name].enable()
    }
  }
}

