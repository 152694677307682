import {Client, IUserResponse, UserLoginRequest, TokenResponse, ITokenResponse, ApiClient} from '../../app/ApiClient';
import { Injectable } from '@angular/core';
import { State, Selector, Action, StateContext } from '@ngxs/store'
import * as moment from 'moment'
import { Navigate } from '@ngxs/router-plugin'
import {LoginRedirect, ResetUser, HomeRedirect, SetToken, SetUser, SetAgent} from '../actions/user.actions';
import {RoleProvider} from "../../enum/role-provider.enum";

export class User {
  user: IUserResponse;
  token: string;
  expired: number;
  agent: string;
  isMobile: boolean;
}

let token = ''
const defaults = {
  user: null,
  token: '',
  expired: 0,
  agent: '',
  isMobile: false
}

@State<User>({
  name: 'user',
  defaults
})
@Injectable()
export class UserState {

  constructor(
    private apiClient: ApiClient) { }

  @Selector()
  static getUser(user: User): IUserResponse {
    return user.user;
  }

  @Selector()
  static isAuthorized(user: User): boolean {
    return !!(UserState.localRememberMeStatus ? UserState.localToken : (UserState.sessionToken || '')) &&
    !!user && !!user.user && !!user.user.id
  }

  @Selector()
  static isOperator(user: User): boolean {
    return !!user && String(user.user.roleName) === String(RoleProvider.Operator);
  }

  @Selector()
  static isAdministratorNabix(user: User): boolean {
    return !!user && String(user.user.roleName) === String(RoleProvider.AdministratorNabix);
  }

  @Selector()
  static isSuperAdministratorNabix(user: User): boolean {
    return !!user && String(user.user.roleName) === String(RoleProvider.SuperAdministratorNabix);
  }

  @Selector()
  static isAdministratorMTS(user: User): boolean {
    return !!user && String(user.user.roleName) === String(RoleProvider.AdministratorMTS);
  }

  @Selector()
  static isSuperAdministratorMTS(user: User): boolean {
    return !!user && String(user.user.roleName) === String(RoleProvider.SuperAdministratorMTS);
  }


  @Selector()
  static isMobile(user: User): boolean {
    return !!user ? !!user.isMobile : false;
  }

  @Action(LoginRedirect)
  onLoginRedirect(ctx: StateContext<UserState>) {
    ctx.dispatch(new Navigate(['/auth/login']));
  }

  @Action(ResetUser)
  ResetUser({ setState, dispatch }: StateContext<User>) {
    setState(defaults);
    this.cleanSessionToken();
    token = null;
    dispatch(new Navigate(['/auth/login']));
  }

  @Action(HomeRedirect)
  HomeRedirect(ctx: StateContext<UserState>) {
    ctx.dispatch(new Navigate(['/home']));
  }

  @Action(SetToken)
  SetToken({ setState, patchState }: StateContext<User>, { data }) {
    const response = data as ITokenResponse;
    const token = !!response && !!response.value ? response.value : '';
    const expired = !!response && !!response.expired ? response.expired : 0;
    this.sessionToken = token;
    patchState({ token, expired });
  }

  @Action(SetAgent)
  SetAgent({ patchState, dispatch }: StateContext<User>, { data }) {
    const isMobile = /Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(data);
    patchState({ isMobile, agent: data });
  }

  @Action(SetUser)
  SetUser({ patchState, dispatch }: StateContext<User>, { user }) {
    patchState({ user });
  }

  cleanSessionToken() {
    sessionStorage.removeItem('sessionToken');
  }

  set sessionToken(token: string) {
    sessionStorage.setItem('sessionToken', token);
  }

  static get localToken() {
    const data = localStorage.getItem('@@STATE');
    if (!data) {
      return null;
    }
    const temp = JSON.parse(data);
    if (!temp || !temp.user) {
      sessionStorage.removeItem('sessionToken');
      localStorage.clear();
      return null;
    }
    return !!temp.user.token ? temp.user.token : token;
  }

  static get localRememberMeStatus() {
    const data = localStorage.getItem('@@STATE');
    const temp = JSON.parse(data);
    if (!temp || !temp.user) {
      sessionStorage.removeItem('sessionToken');
      localStorage.clear();
      return false;
    }
    return !!temp && !!temp.user && temp.user.user ? !!temp.user.user.rememberMe : false;
  }


  static get sessionToken() {
    const temp = sessionStorage.getItem('sessionToken');
    return !!temp ? sessionStorage.getItem('sessionToken') : this.localToken;
  }
}
