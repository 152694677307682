import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';

@Injectable({
  providedIn: 'root',
})
export class BehaviorService {
  public inputHintDetectStatus = new BehaviorSubject<string>(null);
  inputHintDetectChange$ = this.inputHintDetectStatus.asObservable();

  public initCenterStatus = new BehaviorSubject<boolean>(null);
  initCenterChange$ = this.initCenterStatus.asObservable();

  public controlParamsStatus = new BehaviorSubject<boolean>(null);
  controlParamsChange$ = this.controlParamsStatus.asObservable();

  public activeWrapperIdStatus = new BehaviorSubject<string>(null);
  activeWrapperIdChange$ = this.activeWrapperIdStatus.asObservable();

  public mainMenuLastVisibleStatus = new BehaviorSubject<number>(null);
  mainMenuLastVisibleChange$ = this.mainMenuLastVisibleStatus.asObservable();

  public paginatorSource = new BehaviorSubject<[number, number, number, string]>(null)
  paginatorChange$ = this.paginatorSource.asObservable()

  constructor() {}
}
