import { inputTypes } from "src/constants/common.constants";
import {IDataSelectedHelper} from "../models/common.models";
import {RoleProvider, RoleProviderRU} from "../../enum/role-provider.enum";
import {FormGroup} from "@angular/forms";
import {IInvalidFieldInfo} from "../../app/ApiClient";

export function rnd(length) {
  return Math.floor(Math.pow(10, length-1) + Math.random() * 9 * Math.pow(10, length-1));
}

export function rndMax(max) {   // 3 => 0 1 2
  return Math.floor(Math.random() * Math.floor(max));
}

export function rndBool() {
  return rnd(1) % 2 === 0
}

export function notNullOrUndefined(value: any): boolean {
  return value !== null && value !== undefined
}

export function trimAndUpper(str: string): string {
  return !str ? '' : str.replace(/\s/g, '').toUpperCase()
}

export function inRange(val: number, r1: number, r2: number): boolean {
  return val >= r1 && val <= r2
}

export function convertToKeyboard(value: string, key1: string, key2: string): string {
  let result = ''
  const temp = Object.assign([], value)
  const arr1 = Object.assign([], key1)
  const arr2 = Object.assign([], key2)
  temp.map(x => {
    const index = arr1.findIndex(y => y === x)
    result = index > -1 && arr2[index] ? result + arr2[index] : result + x
  })
  return result
}

export function checkNullableDate(date: Date, utc: number, abortText: string): string {
  if (date) {
    return String(date)
  }
  return abortText
}

export function isBroadcatsRoute(url: string): boolean {
  return url.includes('broadcast-message')
}

export function cleanMessage(str: string): string {
  return str.replace(/\s\s/g, '').replace(/{/g, '').replace(/}/g, '')
}

export function isExpectedError(error: any): boolean {
  return !!error && (!!error.status || !!error.statusCode)
}

export const chartColors = [
  "#1CAAFC",
  "#F1A038",
  "#8153CA",
  "#CADD9F",
  "#1F7AC4",
  "#5141C2",
  "#008FFB",
  "#00E396",
  "#FEB019",
  "#FF4560",
  "#775DD0",
  "#546E7A",
  "#26A69A",
  "#D10CE8"
]

export function trimSpaces(val: any): string {
  let temp = String(val);
  temp = temp.replace(/ /gi, '');
  return temp;
}

export function copy(src: any) {
  return Object.assign({}, src);
}

export function getStartOfDay(date: any): Date {
  const d1 = new Date(date);
  d1.setHours(0, 0, 0, 0);
  return d1;
}

export function getIdByValue(data: IDataSelectedHelper[], value: string): any {
  if (!data) {
    return null;
  }
  const index = data.findIndex(x => String(x.id) === String(value) || String(x.label) === String(value));
  return index > -1 ? data[index].id : null;
}

export function getValueById(data: IDataSelectedHelper[], id: string): any {
  if (!data) {
    return null;
  }
  const index = data.findIndex(x => String(x.id) === String(id) || String(x.label) === String(id));
  return index > -1 ? data[index].label : null;
}

export function getSelectId(value: string, arr: any, isArr: boolean): any {
  if (!arr || !value) {
    return isArr ? [] : null;
  }
  if (isArr) {
    const result: any[] = [];
    const vallArr = value.split(', ');
    vallArr.map(x => {
      const temp = getSelectId(x, arr, false);
      if (!!temp) {
        result.push(temp);
      }
    });
    return result;
  }
  const index = arr.findIndex((x: any) => String(x.value) === String(value) || String(x.id) === String(value) || String(x.label) === String(value));
  return index > -1 ? arr[index].id : null;
}

export function formatString(str: string, ...val: string[]): string {
  for (let index = 0; index < val.length; index++) {
    str = str.replace(`{${index}}`, val[index]);
  }
  return str;
}

export function pwdWeightAnalyze(value: string): number {
  // Вес пароля устанавливается равным нулю.
  let weight = 0;
  const pwd = value;
  // Если длина пароля менее 6 символов, то работа алгоритма заканчивается и возвращается результат “слишком короткий пароль”.
  if (pwd.length < 6) {
    return 0;
  }
  // Вес пароля увеличиваем на величину 4 * len, где len – длина пароля.
  weight = pwd.length * 4;
  // Компрессия пароля
  let step = 0;
  let compressPwd = '';
  let last = '';
  let arr: any[] = [];
  while (step < 4) {
    step++;
    arr = [];
    compressPwd = '';
    switch (step) {
      case 1:
        arr = pwd.match(/.{1,1}/g);
        break;
      case 2:
        arr = pwd.match(/.{1,2}/g);
        break;
      case 3:
        arr = pwd.match(/.{1,3}/g);
        break;
      case 4:
        arr = pwd.match(/.{1,4}/g);
        break;
    }
    last = '';
    // Проводятся попытки сжатия пароля для случаев строк S длинной 2, 3 и 4 символов. Вес пароля уменьшается аналогично на величину len — lenCompress.
    arr.map(x => { if (last !== x) { last = x; compressPwd += x; } });
    weight = weight - (pwd.length - compressPwd.length);
  }
  // Если пароль содержит не меньше 3 цифр, то увеличить вес на 5.
  weight = pwd.match(/\d/g)?.length >= 3 ? weight + 5 : weight;
  // Если пароль содержит не менее 2 знаков, то увеличить вес на 5.
  weight = pwd.match(/[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/g)?.length >= 2 ? weight + 5 : weight;
  // Если пароль содержит буквы как в верхнем так и в нижнем регистрах, то увеличить вес пароля на 10.
  weight = pwd.match(/[A-Z]/g)?.length > 0 && pwd.match(/[a-z]/g)?.length > 0 ? weight + 10 : weight;
  // Если пароль содержит буквы и цифры, то увеличить вес пароля на 15.
  weight = /[a-zA-Z]/g.test(pwd) && /\d/g.test(pwd) ? weight + 15 : weight;
  // Если пароль содержит знаки и цифры, то увеличить вес на 15.
  weight = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/g.test(pwd) && /\d/g.test(pwd) ? weight + 15 : weight;
  // Если пароль содержит буквы и знаки, то увеличить вес на 15.
  weight = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/g.test(pwd) && /[a-zA-Z]/g.test(pwd) ? weight + 15 : weight;
  // Если пароль состоит только из букв или только из цифр, то уменьшить вес пароля на 10.
  weight = (/[a-zA-Z]/g.test(pwd) && !/\d/g.test(pwd) && !/[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/g.test(pwd)) ||
    (!/[a-zA-Z]/g.test(pwd) && /\d/g.test(pwd) && !/[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/g.test(pwd)) ||
    (!/[a-zA-Z]/g.test(pwd) && !/\d/g.test(pwd) && /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/g.test(pwd)) ? weight - 10 : weight;

  // Если вес пароля меньше 0, то установить его равным 0. Если больше 100, то установить равным 100.
  // Пароль, вес которого меньше 34, признаётся “слабым”. Если вес от 34 до 67, то пароль относится к категории “хороший”, а если более 67, то пароль считается “отличным”.
  return weight > 100 ? 100 : (weight < 0 ? 0 : weight);
}

export function validateEmailFn(val: string): boolean {
  return /^\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/g.test(val);
}

export function getAllElementsWithAttribute(attribute: string, value: string): any {
  const matchingElements = [];
  const allElements = document.getElementsByTagName('*');
  for (let i = 0, n = allElements.length; i < n; i++) {
    if (allElements[i].getAttribute(attribute) !== null && allElements[i].getAttribute(attribute) === value) {
      // Element exists with attribute. Add to array.
      matchingElements.push(allElements[i]);
    }
  }
  return matchingElements;
}

export function checkDate(d: string): boolean {
  let err = true;
  if (!d) {
    return false;
  }
  err = checkDateArrayNumberErr(d.split(''));
  if (String(d).length === 10) {
    const minDate = new Date(1900, 0, 0);
    const maxDate = new Date(2100, 0, 0);
    const dateTime = d.split('.');
    let date = new Date(Date.UTC(Number(dateTime[2]), Number(dateTime[1]) - 1, Number(dateTime[0]), 0, 0, 0));
    date = getStartOfDay(date);
    err = checkDateArrayErr(dateTime) || !(date > minDate && date < maxDate);
  }
  return err;
}

export function checkDateArrayNumberErr(arr: string[]): boolean {
  let check = false;
  arr.map((x, index) => {
    if (index !== 2 && index !== 5) {
      if (['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'].indexOf(x) < 0) {
        check = true;
      }
    }
  });
  return check;
}

export function checkDateArrayErr(arr: string[]): boolean {
  if (arr.length !== 3) {
    return true;
  }
  if (Number(arr[1]) > 12 || Number(arr[1]) <= 0) {
    return true;
  }
  const index = [1, 3, 5, 7, 8, 10, 12].findIndex(x => x === Number(arr[1]));
  let maxDay = 30;
  if (index > -1) {
    maxDay = 31;
  } else {
    if (Number(arr[1]) === 2) {
      maxDay = Number(arr[2]) % 4 === 0 ? 29 : 28;
    }
  }
  if (Number(arr[0]) > maxDay || Number(arr[0]) <= 0) {
    return true;
  }
  return false;
}

export function isMobileDevice(): boolean {
  return (typeof window.orientation !== 'undefined') || (navigator.userAgent.indexOf('IEMobile') !== -1);
}

export function isTelInput(type: string): boolean { return !!type && String(type).toUpperCase() === inputTypes.tel.toUpperCase(); }
export function isSearchInput(type: string): boolean { return !!type && String(type).toUpperCase() === inputTypes.search.toUpperCase(); }
export function isSelectInput(type: string): boolean { return !!type && String(type).toUpperCase() === inputTypes.select.toUpperCase(); }
export function isDatepickerInput(type: string): boolean { return !!type && String(type).toUpperCase() === inputTypes.datepicker.toUpperCase(); }
export function isStringdateInput(type: string): boolean { return !!type && String(type).toUpperCase() === inputTypes.stringdate.toUpperCase(); }
export function isCardInput(type: string): boolean { return !!type && String(type).toUpperCase() === inputTypes.card.toUpperCase(); }
export function isNumberInput(type: string): boolean { return !!type && String(type).toUpperCase() === inputTypes.number.toUpperCase(); }
export function isTextInput(type: string): boolean { return !!type && String(type).toUpperCase() === inputTypes.text.toUpperCase(); }
export function isPasswordInput(type: string): boolean { return !!type && String(type).toUpperCase() === inputTypes.password.toUpperCase(); }
export function isTextareaInput(type: string): boolean { return !!type && String(type).toUpperCase() === inputTypes.textarea.toUpperCase(); }
export function isCheckboxInput(type: string): boolean { return !!type && String(type).toUpperCase() === inputTypes.checkbox.toUpperCase(); }
export function isRadioInput(type: string): boolean { return !!type && String(type).toUpperCase() === inputTypes.radio.toUpperCase(); }

export const passportRusVariableArray = [
  'Паспорт гражданина РФ',
  'Паспорт РФ',
  'Паспорт гражданина Российской Федерации',
  'Паспорт Российской Федерации',
  'Паспорт гражданина России',
  'Паспорт России'
]

export const nationalityRusVariableArray = [
  'РФ',
  'Россия',
  'Российская Федерация'
]

export function isPassportRus(value: string): boolean {
  return !!value && (passportRusVariableArray.findIndex(x => x.toUpperCase() === String(value).toUpperCase()) > -1);
}

export function isNationalityRus(value: string): boolean {
  return !!value && notNullOrUndefined(value) ? nationalityRusVariableArray.findIndex(x => x.toUpperCase() === String(value).toUpperCase()) > -1 : false;
}

export function equals(value1: string, value2: string, register = false): boolean {
  return register ? String(value1) === String(value2) : String(value1) === String(value2) || String(value1).toUpperCase() === String(value2).toUpperCase();
}

export function isBoolean(val: any): boolean {
  return typeof val === 'boolean';
}

export function isNumber(val: any): boolean {
  return typeof val === 'number';
}

export function isString(val: any): boolean {
  return typeof val === 'string';
}

export function getObjectFieldsArray(data: any): string[] {
  let arr = [];
  Object.keys(data).forEach(name => {
    arr.push(name);
  });
  return arr;
}

export function getMaxValueByProperty(array: any, property: string): number {
  return Math.max.apply(Math, array.map(function(o) { return o[property]; }));
}

export function getUserRoleRu(role: any): string {
  const result = String(role);
  if (!!RoleProviderRU[role]) {
    return RoleProviderRU[role];
  }
  switch (String(role)) {
    case RoleProvider.Operator:
      return RoleProviderRU.Operator;
    case RoleProvider.AdministratorNabix:
      return RoleProviderRU.AdministratorNabix;
    case RoleProvider.SuperAdministratorNabix:
      return RoleProviderRU.SuperAdministratorNabix;
    case RoleProvider.AdministratorMTS:
      return RoleProviderRU.AdministratorMTS;
    case RoleProvider.SuperAdministratorMTS:
      return RoleProviderRU.SuperAdministratorMTS;
  }
  switch (Number(role)) {
    case 1:
      return RoleProviderRU.Operator;
    case 2:
      return RoleProviderRU.AdministratorNabix;
    case 3:
      return RoleProviderRU.SuperAdministratorNabix;
    case 4:
      return RoleProviderRU.AdministratorMTS;
    case 5:
      return RoleProviderRU.SuperAdministratorMTS;
  }
  return role;
}
export function getUserRole(role: any): string {
  const result = String(role);
  switch (String(role)) {
    case RoleProviderRU.Operator:
      return RoleProvider.Operator;
    case RoleProviderRU.AdministratorNabix:
      return RoleProvider.AdministratorNabix;
    case RoleProviderRU.SuperAdministratorNabix:
      return RoleProvider.SuperAdministratorNabix;
    case RoleProviderRU.AdministratorMTS:
      return RoleProvider.AdministratorMTS;
    case RoleProviderRU.SuperAdministratorMTS:
      return RoleProvider.SuperAdministratorMTS;
  }
  return role;
}
export function getUserRoleEnumId(role: any): number {
  const result = getUserRole(role);
  switch (String(role)) {
    case RoleProvider.Operator:
      return 1;
    case RoleProvider.AdministratorNabix:
      return 2;
    case RoleProvider.SuperAdministratorNabix:
      return 3;
    case RoleProvider.AdministratorMTS:
      return 4;
    case RoleProvider.SuperAdministratorMTS:
      return 5;
  }
  return 1;
}

export function mapInvalidFields(formGroup: FormGroup, fields: IInvalidFieldInfo[]): FormGroup {
  Object.keys(formGroup.controls).forEach(name => {
    if (!!formGroup.get(name)) {
      formGroup.get(name)['apiErrors'] = [];
    }
  });
  if (!fields) {
    return formGroup;
  }
  fields.map(x => {
    if (!!formGroup.get(x.propertyName)) {
      formGroup.get(x.propertyName)['apiErrors'].push(x.errorMessage);
    }
  });
  return formGroup;
}
