import { AbstractControl, ValidatorFn } from '@angular/forms';
import {notNullOrUndefined} from "./helpers/data.helper";

export function validateEmail(): ValidatorFn {
  return (control: AbstractControl): { email: boolean } | null => {
    if (control && control.value) {
      return /^[A-Za-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/g.test(String(control.value))
        ? null
        : { email: true };
    }
    return null;
  };
}

export function validateLogin(): ValidatorFn {
  return (control: AbstractControl): { login: boolean } | null => {
    if (control && control.value) {
      return /^([A-Za-z0-9]|\.|\,|\.|\@){6,}$/g.test(String(control.value))
        ? null
        : { login: true };
    }
    return null;
  };
}

export function validateFIO(): ValidatorFn {
  return (control: AbstractControl): { FIO: boolean } | null => {
    if (control && control.value) {
      return /^[А-ЯЁA-Z]{1}[а-яёa-z]+-?((?<=-)[А-ЯЁа-яёA-Za-z]+)?\s?((?<=\s)[а-яёa-z]+)?\s?((?<=\s)[А-ЯЁA-Z]{1}[а-яёa-z]+)?$/.test(String(control.value))
        ? null
        : { FIO: true };
    }
    return null;
  };
}

export function validateNonSymbol(): ValidatorFn {
  return (control: AbstractControl): { NonSymbol: boolean } | null => {
    if (control && control.value) {
      if (/[~!@#$%^&*?()_+=:;'"]/.test(String(control.value))) {
        return { NonSymbol: true }
      } else {
        return null;
      }
    }
    return null;
  };
}

export function validateNonDigit(): ValidatorFn {
  return (control: AbstractControl): { NonDigit: boolean } | null => {
    if (control && control.value) {
      if (/\d+/.test(String(control.value))) {
        return { NonDigit: true }
      } else {
        return null;
      }
    }
    return null;
  };
}

export function validateCapitalLetter(): ValidatorFn {
  return (control: AbstractControl): { CapitalLetter: boolean } | { NonDigit: boolean } | { NonSymbol: true } | null => {
    if (control && control.value) {
      if (/^[А-ЯЁA-Z0-9~!@#$%?^&*()_+=:;'"]{1}[а-яёa-z0-9~!@#$%^&?*()_+=:;'"]+-?((?<=-)[А-ЯЁа-яёA-Za-z0-9~!@#$%^&?*()_+=:;'"]+)?\s?((?<=\s)[а-яёa-z0-9~!@#$%^&?*()_+=:;'"]+)?\s?((?<=\s)[А-ЯЁA-Z0-9~!@#$%^?&*()_+=:;'"]{1}[а-яёa-z0-9~!@#$%^&?*()_+=:;'"]+)?$/.test(String(control.value))) {
        return null;
      } else {
        return { CapitalLetter: true };
      }
    }
    return null;
  };
}

export function validateEng(): ValidatorFn {
  return (control: AbstractControl): { validateEng: boolean } | null => {
    if (control && control.value) {
      return /^[A-Za-z0-9 ,-]*$/.test(String(control.value)) ? null : { validateEng: true };
    }
    return null;
  };
}

export function validateRus(): ValidatorFn {
  return (control: AbstractControl): { validateRus: boolean } | null => {
    if (control && control.value) {
      return /^[А-Яа-я0-9 ,-]*$/.test(String(control.value)) ? null : { validateRus: true };
    }
    return null;
  };
}

// export function trimValue(): ValidatorFn {
//   return (control: AbstractControl): { trimValue: boolean } | null => {
//     if (typeof control.value === 'string' && typeof control.value !== 'object') {
//       if (control && control.value) {
//         let trimmedVal = control.value.trim();
//         control.value !== trimmedVal && control.setValue(trimmedVal)
//         return (control.value !== trimmedVal) ? null : { trimValue: true }
//       }
//     }
//     return null;
//   }
// }

export function onlyTrue(): ValidatorFn {
  return (control: AbstractControl): { onlyTrue: boolean } | null => {
    if (control) {
      return control.value ? null : { onlyTrue: true };
    }
    return null;
  };
}

export function validateEngWithSymbols(): ValidatorFn {
  return (control: AbstractControl): { validateEng: boolean } | null => {
    if (control && control.value) {
      return /^[A-Za-z0-9\s\-\'\"]*$/.test(String(control.value)) ? null : { validateEng: true };
    }
    return null;
  };
}

export function validateSpace(): ValidatorFn {
  return (control: AbstractControl): { validateSpace: boolean } | null => {
    if (control && control.value) {
      return /\s/.test(String(control.value)) ? { validateSpace: true } : null;
    }
    return null;
  };
}

export function validatePin(): ValidatorFn {
  return (
    control: AbstractControl,
  ): { codeLengthErr: string } | { pwdLengthErr: string } | null => {
    if (control && control.value) {
      const pin = control.value;
      if (pin.length !== 6) {
        return { codeLengthErr: 'Длинна ПИН кода - 6 цифр' };
      }
      return /^\d+$/.test(String(pin))
        ? null
        : { codeLengthErr: 'ПИН код должен состоять только из цифр' };
    }
    return null;
  };
}

export function validateMaxPercentValue(): ValidatorFn {
  return (
    control: AbstractControl,
  ): { validateMaxPercentValue: boolean } | null => {
    if (control && control.value) {
      const value = control.value;

      switch (value) {

        case !!value && String(value).includes('.'):
          const valueDot = value.split('.');
          if (valueDot[0] < 100) {
            return null;
          } else {
            return { validateMaxPercentValue: true };
          }

        case !!value && String(value).includes(','):
          const valueSemicolon = value.split(',');
          if (valueSemicolon[0] < 100) {
            return null;
          } else {
            return { validateMaxPercentValue: true };
          }

        default:
          if (value < 100) {
            return null;
          } else {
            return { validateMaxPercentValue: true };
          }
      }
    }
    return null;
  };
}

export function validateAdult(): ValidatorFn {
  return (
    control: AbstractControl,
  ): { validateAdult: boolean } | { validateAdultMin: boolean } | null => {
    if (control && control.value) {
      const birthDay = new Date(control.value);
      const year = birthDay.getUTCFullYear();
      if (year < 1905) {
        return { validateAdultMin: true };
      }
      const birthDayAdult = new Date(birthDay.setUTCFullYear(year + 18));
      const now = new Date();
      return now < birthDayAdult ? { validateAdult: true } : null;
    }
    return null;
  };
}

export function validateDate(onlyPast: boolean): ValidatorFn {
  return (
    control: AbstractControl,
  ): { validatePast: boolean } | { validateFuture: boolean } | null => {
    if (control && control.value) {
      const dateNow = new Date();
      const dateControl = new Date(control.value);
      if (onlyPast && dateControl > dateNow) {
        return { validatePast: true };
      }
      if (!onlyPast && dateControl < dateNow) {
        return { validateFuture: true };
      }
    }
    return null;
  };
}

export function validateRadioPress(): ValidatorFn {
  return (control: AbstractControl): { validateRadioPress: boolean } | null => {
    if (control) {
      return notNullOrUndefined(control.value) ? null : { validateRadioPress: true };
    }
    return null;
  };
}
