import { Injectable } from "@angular/core"
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router"
import { Store } from "@ngxs/store"
import { Observable } from "rxjs"
import { map } from "rxjs/operators"
import {LoginRedirect, ResetUser} from "../../store/actions/user.actions"
import { UserState } from "../../store/state/user.state"

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(private store: Store,
              private router: Router) { }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    if (!(UserState.localRememberMeStatus ? UserState.localToken : (UserState.sessionToken || ''))){
     this.store.dispatch(new ResetUser());
      return false;
    }
    return true;
  }
}
