import {Component, OnDestroy, OnInit} from '@angular/core';
import {User, UserState} from "../../../../store/state/user.state";
import {Observable, Subject, Subscription} from "rxjs";
import {Select} from "@ngxs/store";
import {IUserResponse} from "../../../ApiClient";

@Component({
  selector: 'app-main-head',
  templateUrl: './main-head.component.html',
  styleUrls: ['./main-head.component.scss']
})
export class MainHeadComponent implements OnInit, OnDestroy {
  protected ngUnsubscribe: Subject<void> = new Subject<void>()
  @Select(UserState.getUser) user$: Observable<IUserResponse>

  user: IUserResponse
  subs = new Subscription()

  today = new Date()

  lastOperation = null;

  constructor() { }

  ngOnInit() {
    this.subs.add(this.user$.subscribe(user => {
      this.user = user;
    }))
  }

  ngOnDestroy() {
    this.subs.unsubscribe()
    this.ngUnsubscribe.next()
    this.ngUnsubscribe.complete()
  }
}
