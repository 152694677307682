<section id="auth">

<div class="auth">
  <div class="left-content-wrapper">
    <div class="left-content">
      <p class="main-title color-white">Свобода переводов!</p>
      <p class="auth-subtitle color-white">Онлайн сервис для Банковских Платежных Агентов</p>
    </div>
  </div>
  <div class="right-content-wrapper">
    <div class="right-content" id="auth_right_content">
      <div class="right-form-wrapper">

        <ng-container *ngIf="isLogin">
          <p class="main-title">{{title}}</p>
          <p class="auth-subtitle">Пожалуйста, войдите в систему, используя свой логин и пароль. Также Вы можете восстановить свой пароль или создать новую учетную запись.</p>

          <form class="auth-login" *ngIf="authGroup" [formGroup]="authGroup">
            <app-input [controlInvalid]="isSubmit" name="Phone" label="Мобильный телефон" placeholder="Мобильный телефон" inputType="tel"
                       [parentForm]="authGroup" autocomplete="off" [isRequired]="true" [wrapperClass]="'mb-30'"
                       maskPrefix="+7" mask=" (000) 000 0000"></app-input>

            <app-input [idInput]="'passwordInput'"  inputType="password" placeholder="Пароль" label="Пароль" name="Password" wrapperClass="mb-30" autocomplete="on"
                       [controlInvalid]="isSubmit" [isRequired]="true" [parentForm]="authGroup"></app-input>

            <app-checkbox  [label]="'Запомнить меня'" [controlInvalid]="isSubmit"
                           name="RememberMe" [wrapperClass]="" [parentForm]="authGroup"></app-checkbox>

            <!--<button class="button border">Кнопка</button>-->

            <div class="btn-wrapper-mobile">
              <button class="button main" [ngClass]="{'disabled': !isLoginCorrect}"
                      [style.width.px]="135" (click)="onLogin()">Войти</button>
              <button class="button unfilled decoration-under" (click)="goto('forgot')" [style.padding-left.px]="0">Забыли пароль?</button>
            </div>
            <div class="btn-wrapper-full">
              <button class="button unfilled decoration-under" (click)="goto('forgot')" [style.padding-left.px]="0">Забыли пароль?</button>
              <button class="button main f-right" [ngClass]="{'disabled': !isLoginCorrect}"
                      [style.width.px]="135" (click)="onLogin()">Войти</button>
            </div>

          </form>
        </ng-container>

        <ng-container *ngIf="isForgot">
          <p class="main-title">{{title}}</p>
          <p class="auth-subtitle">Вы можете восстановить свой Пароль используя адрес электронной почты или номер телефона</p>

          <form class="auth-login" *ngIf="authGroup" [formGroup]="authGroup">

            <app-input *ngIf="isForgotByPhone"
              [controlInvalid]="isSubmit"
              name="Phone"
              label="Мобильный телефон"
              placeholder=""
              inputType="tel"
              [parentForm]="authGroup"
              autocomplete="off"
              [isRequired]="true"
              maskPrefix="+7"
              mask=" (000) 000 0000"
              [wrapperClass]="'mb-30'"></app-input>

            <app-input *ngIf="!isForgotByPhone"
                       [controlInvalid]="isSubmit"
                       name="Email"
                       label="Электронная почта"
                       placeholder=""
                       inputType="text"
                       [parentForm]="authGroup"
                       autocomplete="off"
                       [isRequired]="true"
                       [wrapperClass]="'mb-30'"></app-input>

            <div class="t-align-center mb-20">
              <button class="button unfilled decoration-under" (click)="changeForgotType()">Попробовать через {{isForgotByPhone ? 'почту' : 'телефон'}}</button>
            </div>


            <div class="btn-wrapper-mobile">
              <button class="button main" [ngClass]="{'disabled': !isRestoreCorrect}"
                      [style.width.px]="190" (click)="onForgot()">Восстановить</button>
              <button class="button unfilled decoration-under" (click)="goto('login')" [style.padding-left.px]="0">Авторизация</button>
            </div>
            <div class="btn-wrapper-full">
              <button class="button unfilled decoration-under" (click)="goto('login')" [style.padding-left.px]="0">Авторизация</button>
              <button class="button main f-right" [ngClass]="{'disabled': !isRestoreCorrect}"
                      [style.width.px]="190" (click)="onForgot()">Восстановить</button>
            </div>

          </form>
        </ng-container>

        <ng-container *ngIf="isRestore && isUrlCorrect">
          <p class="main-title">{{title}}</p>
          <p class="auth-subtitle">Укажите Ваш новый пароль, адрес электронной почты и номер телефона</p>

          <form class="auth-login" *ngIf="authGroup" [formGroup]="authGroup">

            <app-input [controlInvalid]="isSubmit"
                       name="Phone"
                       label="Мобильный телефон"
                       placeholder=""
                       inputType="tel"
                       [parentForm]="authGroup"
                       autocomplete="off"
                       [isRequired]="true"
                       maskPrefix="+7"
                       mask=" (000) 000 0000"
                       [wrapperClass]="'mb-30'"></app-input>

            <app-input [controlInvalid]="isSubmit"
                       name="Email"
                       label="Электронная почта"
                       placeholder=""
                       inputType="text"
                       [parentForm]="authGroup"
                       autocomplete="off"
                       [isRequired]="true"
                       [wrapperClass]="'mb-30'"></app-input>

            <app-input [idInput]="'passwordInput'"
                       inputType="password"
                       placeholder="Новый пароль"
                       label="Новый пароль"
                       name="NewPassword"
                       wrapperClass="mb-30"
                       autocomplete="on"
                       [controlInvalid]="isSubmit"
                       [isRequired]="true"
                       [parentForm]="authGroup"></app-input>

            <app-input [idInput]="'passwordInput'"
                       inputType="password"
                       placeholder="Подтверждение нового пароля"
                       label="Подтверждение нового пароля"
                       name="RepeatNewPassword"
                       wrapperClass="mb-30"
                       autocomplete="on"
                       [controlInvalid]="isSubmit"
                       [isRequired]="true"
                       [parentForm]="authGroup"></app-input>



            <div class="btn-wrapper-mobile">
              <button class="button main" [ngClass]="{'disabled': !isForgotCorrect}"
                      [style.width.px]="190" (click)="onRestore()">Восстановить</button>
              <button class="button unfilled decoration-under" (click)="goto('login')" [style.padding-left.px]="0">Авторизация</button>
            </div>
            <div class="btn-wrapper-full">
              <button class="button unfilled decoration-under" (click)="goto('login')" [style.padding-left.px]="0">Авторизация</button>
              <button class="button main f-right" [ngClass]="{'disabled': !isForgotCorrect}"
                      [style.width.px]="190" (click)="onRestore()">Восстановить</button>
            </div>

          </form>

        </ng-container>




      </div>
    </div>
  </div>
</div>


</section>
