<div #leftMenu>
  <app-left-menu
    *ngIf="isAuthorized$ | async"
    [leftMenuOpen]="leftMenuOpen"
    (close)="leftMenuOpen = false"></app-left-menu>
</div>

<header>
  <div class="nav">
    <div class="logo-wrapper" routerLink="home">
      <img  src="../../assets/img/nav/nabix-partner-logo.svg" alt="NABIX PARTNERS" class="nav-logo">
      <div class="separator-wrapper">
        <span class="logo-separator"></span>
      </div>
    </div>

    <ng-container *ngIf="isAuthorized$ | async">
      <div class="hamburger-wrapper" id="hamburger" #hamburger>
        <p class="hamburger" (click)="leftMenuOpen = !leftMenuOpen">|||</p>
      </div>

      <div class="menu-full">
        <div class="nav-list-wrapper">
          <ul class="nav-list">
            <li *ngFor="let item of navs; let i=index; trackBy:trackByFn"
                class="nav-item"
                [routerLink]="item.link" (click)="goto(item.link)"
                routerLinkActive="router-link active">
              {{ item.name }}
            </li>
          </ul>
        </div>

        <div class="nav-account-wrapper">
          <content class="nav-accountBox">
            <div class="nav-bell" id="notifyBtn">
              <img src="/assets/img/icons/bell.svg" id="notifyImg" class="nav-bellIco">
              <!--<sup class="nav-bellCounter pulse" id="notifyCount">0</sup>-->
            </div>
            <div class="nav-account">
              <img [src]="avatar" alt="user" class="nav-accountAvatar">
              <div class="nav-accountInfo">
                <p class="nav-accountName">{{ user.lastName }} {{ user.firstName }}</p>
                <p class="nav-accountPhone">{{ user.phone | phonePipe }}</p>
                <p class="nav-accountOrg">
                  {{ user.roleName }}
                </p>
              </div>
            </div>
          </content>
        </div>
      </div>
    </ng-container>

  </div>
</header>
