import { Component, OnInit, Input } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { fadeInOut, fadeInOutUp, fadeInOutUpStagged } from "src/core/animation";

@Component({
  selector: 'app-control-validator',
  templateUrl: './control-validator.component.html',
  styleUrls: ['./control-validator.component.scss'],
  animations: [fadeInOut, fadeInOutUp, fadeInOutUpStagged],
})
export class ControlValidatorComponent implements OnInit {
  @Input() showErrors = false;
  @Input() nowrap = false;
  @Input() name = '';
  @Input() parentForm?: FormGroup;
  @Input() fromComponent = '';

  constructor() {}

  get currentControl(): any {
    return this.parentForm && this.parentForm.controls[this.name];
  }

  get wrapperErrorHeight(): number {
    return (
      24 * (!!this.currentControl && this.showErrors ? this.errorCount + this.apiErrorCount : 0)
    );
  }

  get errorCount(): number {
    return !!this.currentControl && !!this.currentControl.errors
      ? Object.keys(this.currentControl.errors).length
      : 0;
  }

  get apiErrorCount(): number {
    return !!this.currentControl && !!this.currentControl.apiErrors
      ? this.currentControl.apiErrors.length
      : 0;
  }

  // get canShowError(): boolean {
  //   return this.showErrors || (!!this.currentControl && !!this.currentControl.value);
  // }

  ngOnInit() {}

  showControlErrors(): void {
    console.log(this.currentControl);
  }
}
