<div class="expand" [ngClass]="{'open': expanded}" id="filteredUsers">
  <app-users-modal (modalClosed)="modalClosed($event)"
    #addUser
  ></app-users-modal>

  <div class="expand-header" (click)="toggleExpand()">
    <div class="expand-header-image-wrapper">
      <img src="/assets/img/icons/emp.svg">
    </div>
    <div class="expand-header-title-wrapper">
      <p class="expand-header-title overflow-dots">СОТРУДНИКИ</p>
    </div>
    <div class="expand-header-arrow-wrapper" [ngClass]="{'open': expanded}">
      <img class="expand-header-arrow"
           src="/assets/img/icons/arrow-down-dark.svg">
    </div>
  </div>
  <div class="expand-content" [ngClass]="{'open': expanded}">
    <p class="expand-filter-users download-title" (click)="filterExpand = !filterExpand">Фильтры</p>
    <div class="expand-table-container">
      <div *ngIf="searchForm">
        <div class="expand-filter-container" [ngClass]="{'open': filterExpand}">
          <div class="users-search-wrapper">
            <app-input
              inputType="search"
              placeholder="Поиск"
              name="Search"
              width="full"
              wrapperClass="inputBox-group"
              [parentForm]="searchForm"></app-input>
          </div>
          <div class="users-is-active-wrapper">
            <app-checkbox
              class="inputBox-group knowledge-filter-check"
              [parentForm]="searchForm"
              name="OnlyActive"
              label="Только активные"
            ></app-checkbox>
          </div>
          <div class="reset-all-wrapper-mobile">
            <p class="download-title" (click)="resetAll()"
               *ngIf="isNeedFilterReset">Сбросить фильтр</p>
          </div>
          <div class="users-button-create-wrapper">
            <button class="button main"
            (click)="addUser.toggleModal(null)">
              СОЗДАТЬ
            </button>
          </div>
        </div>
        <div class="reset-all-wrapper-full">
          <p class="download-title" (click)="resetAll()"
             *ngIf="isNeedFilterReset">Сбросить фильтр</p>
        </div>
      </div>



        <div class="table-wrapper" [ngClass]="{'load': isLoad}" *ngIf="!!userResponse && userResponse.users.length > 0 else emptyList">
        <table class="table w-100">
          <thead class="table-header">
          <th>РОЛЬ</th>
          <th
            (click)="setSort(columns.FullName)"
            [ngClass]="{'active': sortField === columns.FullName, 'desc': sortField === columns.FullName && isDesc}">
            ФИО <img class="table-header-arrow_down" src="/assets/img/icons/arrow-down.svg"/></th>
          <th
            (click)="setSort(columns.Phone)"
            [ngClass]="{'active': sortField ==columns.Phone, 'desc': sortField ==columns.Phone && isDesc}">
            ТЕЛЕФОН <img class="table-header-arrow_down" src="/assets/img/icons/arrow-down.svg"/></th>
          <th
            (click)="setSort(columns.Email)"
            [ngClass]="{'active': sortField ==columns.Email, 'desc': sortField ==columns.Email && isDesc}">
            EMAIL <img class="table-header-arrow_down" src="/assets/img/icons/arrow-down.svg"/></th>
          </thead>
          <tbody>
          <tr *ngFor="let user of userResponse.users; let i=index"
            class="table-row" (click)="!isLoad && addUser.toggleModal(user)">
            <td>{{user.roleName | rolePipe}}</td>
            <td>{{user.lastName}} {{user.firstName}} {{user.patronymic}}</td>
            <td>{{user.phone}}</td>
            <td>{{user.email}}</td>
          </tr>
          </tbody>
        </table>
         </div>
      <app-paginator *ngIf="userResponse" [page]="paginator.page" [total]="userResponse.totalCount" [take]="paginator.take" [id]="paginatorId" (pageChange)="pageChanged($event)"></app-paginator>
      <ng-template #emptyList>
        <div class="empty-list">
          <p>{{
            isNeedFilterReset ? 'По Вашему запросу нет результатов...' : 'Пока что нет ни одного сотрудника...'
            }}</p>
        </div>
      </ng-template>

    </div>
  </div>
</div>
