import { keyBoardEng, keyBoardRus } from './../../../../constants/common.constants';
import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { digits } from 'src/constants/common.constants';
import { fadeInOut, fadeInOutUp, fadeInOutUpStagged } from 'src/core/animation';
import { rnd, getStartOfDay, getSelectId, getIdByValue, convertToKeyboard, checkDate } from 'src/core/helpers/data.helper';
import { BehaviorService } from 'src/services/behavior.service';
import {IDataSelectedHelper} from "../../../../core/models/common.models";

interface IInvalid {
  required?: boolean;
  min?: { actual: string; min: number };
  max?: { actual: string; max: number };
  minlength?: { actualLength: number; requiredLength: number };
  maxlength?: { actualLength: number; requiredLength: number };
  pwdEmpty?: string;
  pwdSymbolErr?: string;
  pwdLengthErr?: string;
  pwdDontMatch?: string;
}

export interface ISelectDataSubItem {
  title: string;
  value: string;
}

@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
  animations: [fadeInOut, fadeInOutUp, fadeInOutUpStagged],
})
export class InputComponent implements OnInit, OnDestroy, AfterViewInit {
  exampleHeader = InputComponent;
  protected ngUnsubscribe: Subject<void> = new Subject<void>();

  @Input() placeholder = '';
  @Input() width = 'full';
  @Input() label = null;
  @Input() idInput?: string;
  @Input() wrapperClass?: string;
  @Input() quickValidation = false;
  @Input() multiSelect = false;
  @Input() submitted = false;
  @Input() minimaze = false;
  @Input() invalid = null;
  @Input() controlInvalid = null;
  @Input() invalidDescr = '';
  @Input() showInvalid = true;
  @Input() name = '';
  @Input() mask = '';
  @Input() maskRegExp = '';
  @Input() oneString = false;
  @Input() maskPrefix = '';
  @Input() autocomplete: 'new-password';
  @Input() parentForm?: FormGroup;
  @Input() isRequired = false;
  @Input() inputType = 'text';
  @Input() autofocus = false;
  @Input() hint = '';
  @Input() isLink = false;
  @Input() hintShift = 0;
  @Input() hintTop = '';
  @Input() hintBottom = '';
  @Input() selectData: IDataSelectedHelper[] = [];
  @Input() rows = 5;
  @Input() importantIds: string[] = [];
  @Input() maxLength = 1000;
  @Input() isLoading = false;
  @Input() readonly = false;
  @Input() onlySelect = false;
  @Input() selectArrow = true;
  @Input() selectClear = false;
  @Input() isOnlyText = false;
  @Input() isIcon = true;
  @Input() contentMaxHeight = 200;
  @Input() contentMinHeight = null;
  @Input() contentMinWidth = 0;
  @Input() isLeftSelect = false;
  @Input() state = false;
  @Input() isCheckIcon = false;
  @Input() checkIconState = false;
  @Input() nowrap = false;
  @Input() onlyEng = false;
  @Input() minDate = new Date(1900, 0, 0);
  @Input() maxDate = new Date(2100, 0, 0);
  @Output() controlChange: EventEmitter<any> = new EventEmitter();

  @ViewChild('inputField', { static: false }) inputField: ElementRef;
  @ViewChild('selectList', { static: false }) selectList: ElementRef;
  @ViewChild('suggestList', { static: false }) suggestList: ElementRef;
  @ViewChild('utilityList', { static: false }) utilitytList: ElementRef;

  subs = new Subscription();

  pwdEyeOn = true;

  selectDataAll: IDataSelectedHelper[] = [];
  focusedInputId = null;
  focusedInput = false;
  hoveredInput = false;
  focusedSelectIndex = -1;

  id: string;
  idSuggest: string;
  idUtility: string;

  isPhoneInput = false;
  isSearchInput = false;
  isSelectInput = false;
  isDateInput = false;
  isStringDateInput = false;
  isCardInput = false;
  isNumberInput = false;
  isTextInput = false;
  isPasswordInput = false;
  isTextAreaInput = false;
  isCheckboxInput = false;
  isRadioInput = false;

  arrowSrc = '';
  inputIcon = '';
  inputIconClass = '';
  inputWrapperClass = '';

  wrapperId = `input_wrapper_${rnd(10)}`;

  searchUpdated: Subject<string> = new Subject<string>();

  selectOpen: FormControl;

  previousValue = '';

  @HostListener('window:resize', ['$event'])
  onResize(event): void {
    this.setSelectableContent();
  }

  @HostListener('window:scroll', ['$event'])
  onScroll(event): void {
    this.setSelectableContent();
  }
  @ViewChild('selectInput', { static: false }) selectInput: ElementRef;
  @HostListener('document:click', ['$event.target'])
  private clickOutside(targetElement) {
    if (!this.selectOpenVal) {
      return;
    }
    const inside = this.selectInput.nativeElement.contains(targetElement);
    if (!inside) {
      this.selectOpenSetValue(false);
    }
  }

  constructor(public behavior: BehaviorService) {}

  get selectOpenVal(): boolean {
    return this.selectOpen ? this.selectOpen.value : false;
  }

  get value(): any {
    return this.isReactive ? this.parentForm.value[this.name] : this.state;
  }

  get isReactive(): boolean {
    return !!this.parentForm;
  }

  get getIconInput(): string {
    if (!this.isIcon) {
      return '';
    }
    if (this.isPhoneInput) {
      return '../../../../assets/img/icons/smartphone.svg';
    }

    if (this.isSearchInput) {
      return '../../../../assets/img/icons/search.svg';
    }
    return '';
  }

  get getIconInputClass(): string {
    if (!this.isIcon) {
      return '';
    }
    if (this.isPhoneInput) {
      return 'phone';
    }

    if (this.isSearchInput) {
      return 'search';
    }

    if (this.isDateInput) {
      return 'picker';
    }
    return '';
  }

  get getInputWrapperClass(): string {
    if (this.isRadioInput) {
      return 'radioInput-wrapperr';
    }
    if (this.isCheckboxInput) {
      return 'checkboxInput-wrapper';
    }
    return ((this.isPhoneInput || this.isSearchInput) && this.isIcon) ||
      this.isDateInput ||
      this.isIcon ||
      this.isPasswordInput
      ? 'inputBox-wrapper'
      : 'input-wrapper';
  }

  ngOnInit(): void {
    this.selectOpen = new FormControl(false);
    this.arrowSrc = this.isLeftSelect
      ? '../../../../assets/img/icons/arrow-down-white.svg'
      : '../../../../assets/img/icons/arrow-down.svg';
    this.selectDataAll = this.selectData;
    this.minDate = getStartOfDay(this.minDate);
    this.maxDate = getStartOfDay(this.maxDate);
    this.isPhoneInput = this.inputType === 'tel';
    this.isSearchInput = this.inputType === 'search';
    this.isSelectInput = this.inputType === 'select';
    this.isDateInput = this.inputType === 'picker';
    this.isStringDateInput = this.inputType === 'stringdate';
    this.isCardInput = this.inputType === 'card';
    this.isNumberInput = this.inputType === 'number';
    this.isTextInput = this.inputType === 'text';
    this.isPasswordInput = this.inputType === 'password';
    this.isTextAreaInput = this.inputType === 'textarea';
    this.isCheckboxInput = this.inputType === 'checkbox';
    this.isRadioInput = this.inputType === 'radio';
    if (this.idInput) {
      this.id = this.idInput + this.name;
    } else {
      this.id = `input_${rnd(10)}`;
    }
    // this.id = this.idInput;
    if (this.isSearchInput) {
      this.isIcon = true;
    }
    if (!this.mask) {
      this.mask = '';
    }
    if (!this.maskRegExp) {
      this.maskRegExp = '';
    }
    if (!this.maskPrefix) {
      this.maskPrefix = '';
    }
    if (!this.autocomplete) {
      this.autocomplete = 'new-password';
    }
    this.inputIcon = this.getIconInput;
    this.inputIconClass = this.getIconInputClass;
    this.inputWrapperClass = this.getInputWrapperClass;
    this.behavior.activeWrapperIdChange$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((data) => {
      this.setFocusInput(this.wrapperId === data);
    });
    this.selectableListener();
    if (!!this.currentControl && this.isSelectInput && this.onlySelect) {
      this.parentForm.controls[this.name].valueChanges
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((data) => {
          if (
            getSelectId(data, this.selectDataAll, false) >= -1 &&
            getSelectId(data, this.selectDataAll, true).length > 0
          ) {
            this.previousValue = data;
          }
        });
    }
  }

  selectOpenSetValue(val: boolean): void {
    if (this.disabled || this.readonly) {
      return;
    }
    this.selectOpen.setValue(val);
  }

  ngAfterViewInit(): void {
    this.checkHint();
    if (this.isRadioInput) {
      const id = getIdByValue(this.selectData, this.value);
      const radio = document.getElementById(`${this.id}_${id}`);
      if (radio) {
        radio.setAttribute('checked', '');
      }
    }
  }

  checkHint(): void {
    if (this.hint) {
      this.behavior.inputHintDetectStatus.next(this.wrapperId);
    }
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  getWrapperClass(): string {
    return `${this.wrapperClass} ${this.hint ? 'input-wrapper-hint' : ''}`;
  }

  get required(): boolean {
    const _validator: any =
      this.currentControl.validator && this.currentControl.validator(this.currentControl);
    return _validator && _validator.required;
  }

  get currentControl(): any {
    return this.parentForm && this.parentForm.controls[this.name];
  }

  get errorCount(): number {
    return !!this.currentControl && !!this.currentControl.errors
      ? Object.keys(this.currentControl.errors).length
      : 0;
  }

  get apiErrorCount(): number {
    return !!this.currentControl && !!this.currentControl.apiErrors
      ? this.currentControl.apiErrors.length
      : 0;
  }

  get setInvalidClass(): boolean {
    return (
      !!this.invalid || ((this.apiErrorCount > 0 || this.errorCount > 0) && this.controlInvalid)
    );
  }

  get selectedItem(): any {
    return this.currentControl && this.currentControl.value;
  }

  get disabled(): boolean {
    return this.parentForm.controls[this.name]
      ? this.parentForm.controls[this.name].disabled
      : false;
  }

  trackBy(i) {
    return i;
  }

  clearSelect(): void {
    if (this.readonly || !this.isSelectInput) {
      return;
    }
    this.clearCurrentValue();
    // this.selectOpen = true
    // this.inputField.nativeElement.focus()
  }

  clearCurrentValue(): void {
    this.currentControl.setValue(null);
    this.controlChange.emit(null);
    // if (this.operationName) {
    //   this.checkModelError()
    // }
  }

  onNumberInputChange(e) {
    if (this.readonly || !e) {
      return;
    }
    const check = (e.charCode >= 48 && e.charCode <= 58) || e.charCode === 44 || e.charCode === 46;
    if (!check) {
      e.preventDefault();
      return;
    }
    if (this.isStringDateInput) {
      const { length } = e.target.value;

      if (length === 2 || length === 5) {
        e.target.value += '.';
      }
    }
  }

  selectorClicked(item: IDataSelectedHelper, i: number) {
    if (this.readonly) {
      return;
    }
    const val = item.label;
    this.controlChange.emit(null);
    this.currentControl.setValue(val);
    this.controlChange.emit(val);
    this.selectOpenSetValue(false);
    this.selectData = this.selectDataAll;
  }

  selectorClickedOutside(e) {
    this.selectOpenSetValue(false);
    this.selectorInputBlur(e);
  }

  selectorInputBlur(e) {
    const val = this.selectedItem;
    this.focusedInputId = null;
    if (this.selectedItem) {
      if (this.selectedItem !== e.target.value) {
        this.clearCurrentValue();
      }
    }

    this.currentControl.setValue(val);
    this.controlChange.emit(val);
    // if (this.operationName) {
    //   this.checkModelError()
    // }
  }

  selectorInputSearch(e) {
    if (this.onlySelect) {
      this.currentControl.setValue(this.previousValue);
      return;
    }
    if (!this.isSelectInput) {
      return;
    }
    // if (!this.selectDataAll || this.selectDataAll.length === 0) {
    //   this.selectDataAll = this.selectData
    // }
    if (this.readonly) {
      return;
    }
    const { value } = e.target;
    if (value) {
      this.selectOpenSetValue(true);
    }

    const valKey1 = convertToKeyboard(value, keyBoardRus, keyBoardEng);
    const valKey2 = convertToKeyboard(value, keyBoardEng, keyBoardRus);
    const foundValue = this.selectDataAll.find((v) => v.label === valKey1 || v.label === valKey2);

    if (foundValue) {
      this.currentControl.setValue(foundValue.label);
      this.controlChange.emit(foundValue.label);
      // if (this.operationName) {
      //   this.checkModelError()
      // }
      this.selectOpenSetValue(e.keyCode !== 13);
    } else {
      this.selectData = this.selectDataAll.filter(
        (x) =>
          (x.label && x.label.toLocaleLowerCase().includes(valKey1.toLocaleLowerCase())) ||
          (x.label && x.label.toLocaleLowerCase().includes(valKey2.toLocaleLowerCase())),
      );
      this.selectOpenSetValue(this.selectData && this.selectData.length > 0);
    }
    // if (!this.selectData || this.selectData.length === 0) {
    //   this.selectData = this.selectDataAll
    // }
  }

  inputKeyUp(event: any): void {
    if (this.disabled || this.readonly) {
      return;
    }
    if (this.isSelectInput) {
      this.selectorInputSearch(event);
    }
  }

  inputKeyDown(event: any): void {
    if (this.disabled || this.readonly) {
      return;
    }
    if (this.isSelectInput) {
      this.selectFromList(event);
    }
    if (this.onlyEng) {
     this.engTyping(event);
    }
  }

  engTyping(event) {
    const patt = /[а-яёА-ЯЁ]/gm;
    if (!!event.key && !String(event.key).toLocaleLowerCase().search(patt)) {
      event.preventDefault();
    }
  }

  selectFromList(event) {
    if (this.disabled || this.readonly) {
      return;
    }
    if (
      !this.isSelectInput ||
      this.readonly ||
      !this.selectList ||
      (this.selectList && !this.selectList.nativeElement)
    ) {
      return;
    }
    const el = this.selectList.nativeElement;
    const { length } = el.children;
    if (event.keyCode === 40) {
      if (length > this.focusedSelectIndex + 1) {
        this.focusedSelectIndex++;
        // const test = document.getElementById(`select${this.focusedSelectIndex}`) as HTMLElement
        // test.scrollIntoView()
      }
    }
    if (event.keyCode === 38) {
      if (this.focusedSelectIndex - 1 >= 0) {
        this.focusedSelectIndex--;
        // const test = document.getElementById(`select${this.focusedSelectIndex}`) as HTMLElement
        // test.scrollIntoView()
      }
    }
    if (
      event &&
      event.keyCode === 13 &&
      el &&
      el.children &&
      el.children[this.focusedSelectIndex] &&
      el.children[this.focusedSelectIndex].dataset &&
      el.children[this.focusedSelectIndex].dataset.value
    ) {
      const val = this.selectData[this.focusedSelectIndex].label;
      this.currentControl.setValue(val);
      this.controlChange.emit(val);
      // if (this.operationName) {
      //   this.checkModelError()
      // }
    }
    this.selectOpenSetValue(event.keyCode !== 13);
  }

  changed(event: any): void {
    this.parentForm.controls[this.name].setValue(event);
    this.controlChange.emit(this.parentForm.controls[this.name].setValue(event));
  }

  setFocusInput(state: boolean): void {
    this.focusedInput = state;
    this.checkHint();
  }

  setActiveWrapper(set: boolean): void {
    this.checkHint();
    this.behavior.activeWrapperIdStatus.next(set ? this.wrapperId : null);
  }

  checkDate(e: any): void {
    if (!!e && !e.data) {
      return
    }
    if (!(digits.findIndex((x) => String(x) === e.data) > -1)) {
      e.target.value = String(e.target.value).substring(0, e.target.value.length - 1);
      return;
    }
    if (e.target.value.length > 10) {
      e.target.value = String(e.target.value).substring(0, 10);
      return;
    }
    let date = e.target.value.split('.').join('') as string;
    if (date.length > 2) {
      date = `${date.substring(0, 2)}.${date.substring(2, date.length)}`;
    }
    if (date.length > 5) {
      date = `${date.substring(0, 5)}.${date.substring(5, date.length)}`;
    }
    e.target.value = date;
    this.invalid =
      checkDate(e.target.value) ||
      !this.currentControl.value ||
      !this.currentControl.value.isValid() ||
      !e.target.value ||
      e.target.value.length !== 10;
  }

  selectableListener(): void {
    if (this.isSelectInput) {
      this.selectableChanger('selectOpen');
    }
  }

  selectableChanger(control: string): void {
    if (!this[control]) {
      return;
    }
    this[control].valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe((data) => {
      if (data) {
        setTimeout(() => {
          this.setSelectableContent();
        });
      }
    });
  }

  setSelectableContent(): void {
    if (!this.selectOpenVal) {
      return;
    }
    const dialog = document.getElementById(`add_account_dialog_input_wrapper`);
    const input = document.getElementById(this.wrapperId);
    const content = document.getElementById(`content_${this.id}`);
    if (!input || !content) {
      return;
    }

    let maxWidth = input.offsetWidth ? input.offsetWidth : window.innerWidth - 100;
    maxWidth = maxWidth < 100 ? 100 : maxWidth;
    const elemRectInput = input.getBoundingClientRect();
    let maxHeight = window.innerHeight - elemRectInput.y - input.offsetHeight - 80;
    maxHeight = maxHeight < 100 ? 100 : maxHeight;
    maxHeight = maxHeight > window.innerHeight - 80 ? window.innerHeight - 80 : maxHeight;
    maxHeight = maxHeight > this.contentMaxHeight ? this.contentMaxHeight : maxHeight;
    const minWidth =
      elemRectInput.width - 100 > 0 ? elemRectInput.width - 100 : elemRectInput.width;
    const maxWidthShift = maxWidth >= 100 ? 0 : 50;
    const shiftLabel = !!this.label ? 85 : 50;
    if (dialog) {
      content.setAttribute(
        'style',
        `  position: absolute; top: ${input.offsetHeight - 32}px; max-height: ${
          maxHeight + input.offsetHeight
        }px; width: ${maxWidth + maxWidthShift - 2}px; min-width: ${minWidth}px; opacity: 1`,
      );
    } else {
      content.setAttribute(
        'style',
        `  position: fixed; top: ${elemRectInput.y + shiftLabel}px; max-height: ${maxHeight}px; width: ${
          maxWidth + maxWidthShift - 2
        }px; min-width: ${minWidth}px; opacity: 1`,
      );
    }
  }
}
