<div class="page-wrapper">
  <p class="page-title pb-5">Операции</p>
  <div [style.height.px]="20">
    <p class="download-title" (click)="resetAll()" [style.margin-top.px]="0"
       *ngIf="isNeedFilterReset">Сбросить фильтр</p>
  </div>

  <p class="expand-filter-operations download-title mt-10" (click)="filterExpand = !filterExpand">Фильтры</p>
  <div class="operation-filter-wrapper" [ngClass]="{'open': filterExpand}"
       *ngIf="!!operationFilterForm" [formGroup]="operationFilterForm">
    <div class="operation-filter-item-wrapper">
      <app-input inputType="text"
                 placeholder="Номер операции"
                 label="Номер операции"
                 name="TransferNumber"
                 width="full"
                 wrapperClass="inputBox-group operation-filter-item"
                 [controlInvalid]="submitted"
                 [parentForm]="operationFilterForm"></app-input>
    </div>
    <div class="operation-filter-item-wrapper">
      <app-input inputType="select"
                 placeholder="Сотрудник"
                 label="Сотрудник"
                 name="OperatorId" wrapperClass="inputBox-group operation-filter-item"
                 [controlInvalid]="submitted"
                 [contentMaxHeight]="600" [selectData]="availableUsers || []"
                 [parentForm]="operationFilterForm"></app-input>
    </div>
    <div class="operation-filter-item-wrapper">
      <app-input inputType="select"
                 placeholder="Страна"
                 label="Страна"
                 name="Country" wrapperClass="inputBox-group operation-filter-item"
                 [controlInvalid]="submitted"
                 [contentMaxHeight]="600" [selectData]="countries || []"
                 [parentForm]="operationFilterForm"></app-input>
    </div>
    <div class="operation-filter-item-wrapper">
      <app-input inputType="text"
                 placeholder="Статус"
                 label="Статус"
                 name="Status"
                 width="full"
                 wrapperClass="inputBox-group operation-filter-item"
                 [controlInvalid]="submitted"
                 [parentForm]="operationFilterForm"></app-input>
    </div>
    <div class="operation-filter-item-wrapper">
      <app-input inputType="text"
                 placeholder="Сумма от"
                 label="Сумма от"
                 name="SumFrom"
                 width="full"
                 [mask]="'0*.00'"
                 wrapperClass="inputBox-group operation-filter-item"
                 [controlInvalid]="submitted"
                 [parentForm]="operationFilterForm"></app-input>
    </div>
    <div class="operation-filter-item-wrapper">
      <app-input inputType="text"
                 placeholder="Сумма до"
                 label="Сумма до"
                 name="SumTo"
                 width="full"
                 [mask]="'0*.00'"
                 wrapperClass="inputBox-group operation-filter-item"
                 [controlInvalid]="submitted"
                 [parentForm]="operationFilterForm"></app-input>
    </div>
    <div class="operation-filter-item-wrapper">
      <app-input inputType="picker"
                 placeholder="Дата от"
                 label="Дата от"
                 name="DateFrom"
                 width="full"
                 [mask]="'00.00.0000'"
                 [minDate]="dateFromMin"
                 [maxDate]="dateFromMax"
                 wrapperClass="inputBox-group operation-filter-item"
                 [controlInvalid]="submitted"
                 [parentForm]="operationFilterForm"></app-input>
    </div>
    <div class="operation-filter-item-wrapper">
      <app-input inputType="picker"
                 placeholder="Дата до"
                 label="Дата до"
                 name="DateTo"
                 width="full"
                 [mask]="'00.00.0000'"
                 [minDate]="dateToMin"
                 [maxDate]="dateToMax"
                 wrapperClass="inputBox-group operation-filter-item"
                 [controlInvalid]="submitted"
                 [parentForm]="operationFilterForm"></app-input>
    </div>
    <div class="operation-filter-item-wrapper">
      <app-input inputType="text"
                 placeholder="Система"
                 label="Система"
                 name="PaymentSystemId"
                 width="full"
                 wrapperClass="inputBox-group operation-filter-item"
                 [controlInvalid]="submitted"
                 [parentForm]="operationFilterForm"></app-input>
    </div>
    <div class="operation-filter-item-wrapper">
      <app-input inputType="select"
                 placeholder="Валюта приема"
                 label="Валюта приема"
                 name="AcceptedCurrency" wrapperClass="inputBox-group operation-filter-item"
                 [controlInvalid]="submitted"
                 [contentMaxHeight]="600" [selectData]="currencies || []"
                 [parentForm]="operationFilterForm"></app-input>
    </div>
    <div class="operation-filter-item-wrapper">
      <app-input inputType="select"
                 placeholder="Валюта выдачи"
                 label="Валюта выдачи"
                 name="WithdrawCurrency" wrapperClass="inputBox-group operation-filter-item"
                 [controlInvalid]="submitted"
                 [contentMaxHeight]="600" [selectData]="currencies || []"
                 [parentForm]="operationFilterForm"></app-input>
    </div>
    <div class="operation-filter-item-wrapper p-relative">
      <div class="operation-filter-item operation-filter-button-wrapper">
        <button class="button main search" (click)="getFilteredOperations()">
          Поиск
        </button>
<!--        <div class="xls-wrapper" (click)="download()" [ngClass]="{'opacity-05 disabled': downloadInProgress}">
          <img class="oper-icon" src="../../../assets/img/icons/xls.svg">
        </div>-->
      </div>
    </div>
  </div>

</div>
