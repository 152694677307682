import {Component, OnDestroy, OnInit} from '@angular/core';
import {Observable, Subject, Subscription} from "rxjs";
import {Guid} from "guid-typescript";
import {FormControl, FormGroup} from "@angular/forms";
import {NotifyService} from "../../../../../services/notify.service";
import {debounceTime, map, takeUntil} from "rxjs/operators";
import {Store} from "@ngxs/store";
import {createPaginator} from "../../../../../core/helpers/paginator.helper";
import {getSelectId} from "../../../../../core/helpers/data.helper";
import {
  ApiClient,
  Client,
  IPaginatorRequest,
  IUserFilterRequest, IUserFilterResponse,
  PaginatorRequest,
  UserFilterEnum,
  UserFilterRequest
} from "../../../../ApiClient";

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})

export class UsersComponent implements OnInit, OnDestroy {
  protected ngUnsubscribe: Subject<void> = new Subject<void>()

  subs = new Subscription();
  paginatorId = Guid.raw();
  paginator: IPaginatorRequest;
  requestSub: any;

  userResponse: IUserFilterResponse;

  expanded = false;
  isFirst = true;
  valueChangeFirst = true;
  isLoad = false;
  filterExpand = false;

  searchForm: FormGroup;
  search: FormControl;
  onlyActive: FormControl;

  searchType = 0;

  columns = UserFilterEnum;
  sortField: UserFilterEnum = UserFilterEnum.FullName;
  isDesc = true;

  constructor(
    private notify: NotifyService,
    private apiClient: ApiClient,
    private store: Store
  ) { }

  get isNeedFilterReset(): boolean {
    return this.searchForm && (
      !!this.searchForm.value.Search ||
      !!this.searchForm.value.OnlyActive
    )
  }

  ngOnInit() {
    this.paginator = createPaginator(5);
    this.createFormControls();

    this.getFilteredUsers();
  }

  createFormControls(): void {
    this.search = new FormControl(null);
    this.onlyActive = new FormControl(false);

    this.searchForm = new FormGroup({
      Search: this.search,
      OnlyActive: this.onlyActive
    });
  }

  resetAll(): void {
    this.searchForm.controls.Search.setValue(null);
    this.searchForm.controls.OnlyActive.setValue(false);
    this.getFilteredUsers();
  }

  ngOnDestroy() {
    this.subs.unsubscribe()
    this.ngUnsubscribe.next()
    this.ngUnsubscribe.complete()
    this.checkRequestSub()
  }

  valueChangeListener(): void {
    this.searchForm.valueChanges
      .pipe(
        map(data => ({
          search: data.Search,
          onlyActive: data.OnlyActive
        })),
        takeUntil(this.ngUnsubscribe),
        debounceTime(500))
      .subscribe(data => {
        this.getFilteredUsers();
      })
  }

  checkRequestSub(): void {
    if (this.requestSub) {
      this.requestSub.unsubscribe()
    }
  }

  toggleExpand(): void {
    this.expanded = !this.expanded
    if (this.expanded && this.isFirst) {
      this.isFirst = false
      this.getFilteredUsers()
    }
  }

  modalClosed(event: any) {
    if (!!event) {
      this.getFilteredUsers();
    }
  }

  getFilteredUsers(): void {
    this.checkRequestSub()
    const payload: IUserFilterRequest = {
      paginator: this.paginator as PaginatorRequest,
      column: this.sortField,
      isDesc: this.isDesc,
      query: this.searchForm.value.Search,
      onlyActive: this.searchForm.value.OnlyActive,
      roles: []
    };
    this.requestSub = this.apiClient.users_GetFilteredUsers(payload as UserFilterRequest).pipe(takeUntil(this.ngUnsubscribe))
    .subscribe(data => {
      this.userResponse = data as IUserFilterResponse;
    }, error => {
      this.notify.error(error.message)
      console.error(error)
    });
    if (this.valueChangeFirst) {
      this.valueChangeFirst = false
      this.valueChangeListener()
    }
  }

  userChanged(): void {
    this.getFilteredUsers();
  }

  setSort(type): void {
    this.getFilteredUsers();
  }

  pageChanged(event: any): void {
    this.paginator.page = Number(event);
    this.getFilteredUsers();
  }
}
