export const messages = {
  emergencyLinkSet: 'Временная ссылка установлена',
  newDocReady: 'Новый документ готов',
  passwordChange: 'Пароль успешно изменен',
  passwordReset: 'Пароль успешно сброшен',
  elementsRemoved: 'Элементы удалены',
  elementRemoved: 'Элемент удален',
  checksPrintingEnd: 'Чеки распечатаны',
  docForDownloadNotFound: 'Нет документов для скачивания',
  rejectRequestSent: 'Заявка на аннулирование операции отправлена',
  orgUpdated: 'БПА был успешно обновлен',
  orgCreated: 'БПА был успешно создан',
  orgAddToGroup: 'БПА успешно привязан к группе',
  bpaSettingUpdated: 'Настройки БПА успешно обновлены',
  orgRemoveFromGroup: 'БПА успешно отвязан от группе',
  pointUpdated: 'Точка была успешно обновлена',
  pointCreated: 'Точка была успешно создана',
  operationUpdated: 'Операция была успешно обновлена',
  operationCreated: 'Операция была успешно создана',
  groupUpdated: 'Группа БПА была успешно обновлена',
  groupCreated: 'Группа БПА была успешно создана',
  fileDeleted: 'Файл был успешно удален',
  userUpdated: 'Сотрудник был успешно обновлен',
  userCreated: 'Сотрудник был успешно создан',
  fileCreated: 'Файл был успешно добавлен',
  fileUpdated: 'Файл был успешно обновлен',
  limitUpdated: 'Лимит был успешно обновлен',
  limitCreated: 'Лимит был успешно создан',
  limitRemoved: 'Лимит был успешно удален',
  tarifFieldUpdated: 'Тарифные поля были успешно обновлены',
  tarifFieldCreated: 'Тарифные поля были успешно созданы',
  operationConfirm: 'Операция была успешно подтверждена',
  operationsConfirm: 'Операции были успешно подтверждены',
  passwordSendToEmail: 'Новый пароль был отправлен на указанную почту',
  settingsUpdated: 'Настройки успешно обновлены',
  fileUrlUpdated: 'Ссылка на файл была успешно обновлена',
  reportAddToQueue: 'Отчет добавлен в очередь для формирования',
  proposalCreate: 'Заявка на привязку отправлена',
  proposalUpdateApproved: 'Заявка успешно одобрена',
  proposalUpdateRemoved: 'Заявка успешно отклонена',
  pointUpdateApproved: 'Компьютер успешно привязан к точке',
  pointUpdateRemoved: 'Компьютер успешно отвязан от точки',
  hardwareRemoved: 'Компьютер успешно отвязан',
  proposalFromUserExist: 'Сообщите код Администратору БПА или НАБИКС',
  copyToClipboard: 'Текст скопирован в буфер обмена',
  checkEmpFileAndConfirm: 'Проверьте данные и нажмите подтвердить',
  chooseEmpFile: 'Выберите файл с данными для обработки',
  clickParsing: 'Нажмите обработать',
  parsingProgress: 'Обработка',
  passwordChanged: 'Пароль был успешно изменен',
  setAmountFromAdminSuccess: 'Сумма для нулевой операции успешно установлена',
  checkAddToQueue: 'Чек успешно добавлен в очередь',
  checksAddToQueue: 'Чеки успешно добавлен в очередь',
  jobComplete: 'Планировщик выполнил задачу',
  accessElementRemove: 'Запись о печати успешно удалена',
  operationCheckFlagRemove: 'Флаг печати чека сброшен',
  rejectSdComplete: 'Флаги для аннулирования после SD проставлены',
  rejectTypeAdd: 'Флаг для аннулирования проставлен',
  rejectTypeRemove: 'Флаг для аннулирования удален',
  selectAll: 'Выбрать все',
  unselectAll: 'Отменить выбор',
  docSigned: 'Документ подписан сертификатом БПА',
  endWihtoutCheck: 'Вы можете продолжить совершение операции. Обратите внимание, что фискальный чек также не будет распечатан при подтверждении до тех пор, пока служба поддержки NABIX Partner не устранит проблему.',
  checkEmpFileViaApi: 'После редактирования необходимо проверить возможность изменения сотрудников. Для этого нажмите проверить',
  notFiscalOperationCompleatedEmpty: 'Нет завершенных операций без фискальных чеков',
  issueCreated: 'Заявка успешно создана',
  issueUpdated: 'Заявка успешно изменена',
  errNotFound: 'Ошибки устранены',
  faqTagUpdated: 'Группа FAQ была успешно обновлена',
  faqTagCreated: 'Группа FAQ была успешно создана',
  faqTagRemoved: 'Группа FAQ была успешно удалена',
  faqUpdated: 'Вопрос был успешно обновлен',
  faqCreated: 'Вопрос был успешно создан',
  faqRemoved: 'Вопрос был успешно удален',
  markSet: 'Спасибо за Ваш отзыв'
}
