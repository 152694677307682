import { InputComponent } from './pages/common/input/input.component';
import { HeaderComponent } from './pages/common/header/header.component';
import { CommonModule, DatePipe, registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import localeEN from '@angular/common/locales/en';
import localeRU from '@angular/common/locales/ru';
import { ErrorHandler, Injector, LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
  MAT_RIPPLE_GLOBAL_OPTIONS,
  RippleGlobalOptions,
} from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router } from '@angular/router';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
// ngxs plugins
import { NgxsRouterPluginModule } from '@ngxs/router-plugin';
import { NgxsStoragePluginModule, STORAGE_ENGINE } from '@ngxs/storage-plugin';
import { NgxsModule } from '@ngxs/store';
import * as Sentry from '@sentry/angular';
import { HighchartsChartModule } from 'highcharts-angular';
import { RECAPTCHA_SETTINGS, RecaptchaModule, RecaptchaSettings } from 'ng-recaptcha';
import { IConfig, NgxMaskModule } from 'ngx-mask';
import { ToastrModule } from 'ngx-toastr';
import { environment } from 'src/environments/environment';

import { AppComponent } from './app.component';
import { NotifyService, notifySettings } from '../services/notify.service';
import { AppRoutingModule } from './app-routing.module';
import { BaseUrlInterceptor } from '../core/interceptors/api.interceptors';
import { MyStorageEngine } from '../core/storage.engine';
import {API_BASE_URL, ApiClient } from './ApiClient';
import { isLocalEnv } from '../core/helpers/env.helper';
import { UserState } from '../store/state/user.state';
import { AuthComponent } from './pages/auth/auth.component';
import { BehaviorService } from 'src/services/behavior.service';
import { ControlValidatorComponent } from './pages/common/control-validator/control-validator.component';
import {CheckboxComponent} from "./pages/common/checkbox/checkbox.component";
import { PersonalAreaComponent } from './pages/personal-area/personal-area.component';
import { HomeComponent } from './pages/home/home.component';
import { FooterComponent } from './pages/common/footer/footer.component';
import { OperationsComponent } from './pages/operations/operations.component';
import { SupportComponent } from './pages/support/support.component';
import { SettingsComponent } from './pages/settings/settings.component';
import {PhonePipe} from "../core/pipes/phone.pipe";
import { MainHeadComponent } from './pages/common/main-head/main-head.component';
import { MainOperationsComponent } from './pages/common/main-operations/main-operations.component';
import { UsersComponent } from './pages/common/settings/users/users.component';
import { PaginatorComponent } from './pages/common/paginator/paginator.component';
import { UsersModalComponent } from './pages/common/settings/users-modal/users-modal.component';
import {DictionaryState} from "../store/state/dictionary.state";
import {RolePipe} from "../core/pipes/role.pipe";
import { UserSettingsComponent } from './pages/common/settings/user-settings/user-settings.component';
import { LeftMenuComponent } from './pages/common/left-menu/left-menu.component';
import { TemporaryPasswordComponent } from './dialogs/temporary-password/temporary-password.component';

export const MY_FORMATS = {
  parse: {
    dateInput: 'DD.MM.YYYY',
  },
  display: {
    dateInput: 'DD.MM.YYYY',
    monthYearLabel: 'MMMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};
const globalRippleConfig: RippleGlobalOptions = {
  disabled: true,
  animation: {
    enterDuration: 300,
    exitDuration: 0,
  },
};

export const options: Partial<IConfig> | (() => Partial<IConfig>) = null;

registerLocaleData(localeRU, 'ru');
registerLocaleData(localeEN, 'en');

@NgModule({
  declarations: [
    AppComponent,
    AuthComponent,
    HeaderComponent,
    InputComponent,
    ControlValidatorComponent,
    CheckboxComponent,
    PersonalAreaComponent,
    HomeComponent,
    FooterComponent,
    OperationsComponent,
    SupportComponent,
    SettingsComponent,
    PhonePipe,
    RolePipe,
    MainHeadComponent,
    MainOperationsComponent,
    UsersComponent,
    PaginatorComponent,
    UsersModalComponent,
    UserSettingsComponent,
    LeftMenuComponent,
    TemporaryPasswordComponent
  ],
  entryComponents:
    [
      // dialogs
      TemporaryPasswordComponent,
    ],
  imports: [
    AppRoutingModule,
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    HighchartsChartModule,
    NgxsModule.forRoot([UserState, DictionaryState], {
      developmentMode: isLocalEnv(),
    }),
    NgxsRouterPluginModule.forRoot(),
    NgxsReduxDevtoolsPluginModule.forRoot(),
    // NgxsLoggerPluginModule.forRoot(),
    NgxsStoragePluginModule.forRoot(),
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    NgxMaskModule.forRoot(options),
    MatDatepickerModule,
    MatIconModule,
    MatDialogModule,
    MatMenuModule,
    MatProgressSpinnerModule,
    ToastrModule.forRoot(notifySettings()),
    RecaptchaModule,
  ],
  // entryComponents: [AddAccountDialogComponent, RegisterCodeDialogComponent],
  providers: [
    // {
    //   provide: ErrorHandler,
    //   useFactory: getErrorHandler,
    //   deps: [Injector],
    // },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: BaseUrlInterceptor,
      multi: true,
    },
    // {
    //   provide: RECAPTCHA_SETTINGS,
    //   useValue: {
    //     siteKey: environment.reCaptchaKey,
    //   } as RecaptchaSettings,
    // },
    { provide: API_BASE_URL, useValue: environment.apiUrl },
    { provide: LOCALE_ID, useValue: 'ru-RU' },
    { provide: MAT_RIPPLE_GLOBAL_OPTIONS, useValue: globalRippleConfig },
    {
      provide: STORAGE_ENGINE,
      useClass: MyStorageEngine
    },
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    DatePipe,
    // CustomDatePipe,
    // AuthService,
    // AuthGuard,
    ApiClient,
    BehaviorService,
    NotifyService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
