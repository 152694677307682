import { Component, HostListener, OnInit } from '@angular/core';
import {Select, Store} from "@ngxs/store";
import {UserState} from "../store/state/user.state";
import {forkJoin, Observable, of, Subscription} from "rxjs";
import {
  GetAvailableData,
  ResetDictionaries,
  SetCountries,
  SetCurrencies, SetServicePoints,
  SetServices, SetTimeZones,
  SetUserRoles
} from "../store/actions/dictionary.actions";
import {catchError, takeUntil} from "rxjs/operators";
import {ApiClient} from "./ApiClient";
import {BehaviorService} from "../services/behavior.service";
import {ResetUser, SetAgent} from "../store/actions/user.actions";
import {UserAvailableDataEnum} from "../enum/user-available-data";
import {isExpectedError} from "../core/helpers/data.helper";
import {errorMessages} from "../constants/errors";
import {NotifyService} from "../services/notify.service";
import {MatDialog} from "@angular/material/dialog";
import {TemporaryPasswordComponent} from "./dialogs/temporary-password/temporary-password.component";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  @Select(UserState.isAuthorized) isAuthorized$: Observable<boolean>;
  subs = new Subscription();
  title = 'MTSWebClient';

  screenWidth = 0;

  isRequestSendToDictionary = false;
  isRequestSendToTemporary = false;
  temporaryActive = false;
  isAuthorized = false;

  @HostListener('window:resize', ['$event'])
  onResize(event: any): void {
    setTimeout(() => {
      this.initScreen(event.target.innerWidth);
      this.initCenter();
      this.store.dispatch(new SetAgent(navigator.userAgent));
    }, 100);
  }

  constructor(
    private  apiClient: ApiClient,
    private store: Store,
    private behavior: BehaviorService,
    private notify: NotifyService,
    private dialog: MatDialog,
  ) {
  }

  ngOnInit(): void {
    this.subs.add(this.isAuthorized$.subscribe(isAuthorized => {
      this.isAuthorized = isAuthorized;
      if (this.isAuthorized) {
        this.getStaticDictionaries();
        this.checkTemporary();
      } else {
        this.isRequestSendToDictionary = false;
        this.isRequestSendToTemporary = false;
        this.store.dispatch(new ResetDictionaries());
        setTimeout(() => {
          this.initCenter();
        }, 100);
      }
    }));
    setTimeout(() => {
      this.initCenter();
      this.initScreen(window.innerWidth);
    }, 100);
    this.behavior.initCenterChange$
      .subscribe(data => {
        if (!!data) {
          this.initCenter();
        }
      });
  }

  initScreen(screenWidth: number): void {
    this.screenWidth = screenWidth;
    this.initHints();
  }

  initCenter(): void {
    const arr = [
      { wrapper: 'auth', el: 'auth_right_content'},
      { wrapper: 'support_page', el: 'support_wrapper'}
    ];
    const mainContainer = document.getElementById('main_container');
    const mainContainerHeight = !!mainContainer ? mainContainer.offsetHeight : 0;
    arr.map(x => {
      let wrapper = document.getElementById(x.wrapper);
      let el = document.getElementById(x.el);
      if (!!wrapper && !!el) {
        const wrapperHeight = wrapper.offsetHeight;
        const elHeight = el.offsetHeight;
        let newStyleEl = '';
        if (wrapperHeight > elHeight + 10 ||
          mainContainerHeight > elHeight + 10) {
          newStyleEl = 'position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%);';
        }
        el.setAttribute('style', newStyleEl);
      }
    });
  }

  initHints(): void {
    const wrappersHint = document.getElementsByClassName('input-wrapper-hint');
    if (!wrappersHint || wrappersHint.length === 0) {
      return;
    }
    const addAccountWrapperEl = document.getElementById('hint-main-wrapper');
    for (let i = 0; i <= wrappersHint.length - 1; i++) {
      this.setHintStyle(
        addAccountWrapperEl,
        wrappersHint[i],
        wrappersHint[i].getAttribute('id'),
        true,
      );
    }
  }

  setHintStyle(addAccountWrapperEl: any, el: any, wrapperId: string, isFirst = false): void {
    if (!el) {
      return;
    }
    // Получим координаты элемента для подсказки
    const wrapperElAll = addAccountWrapperEl || el;
    const widthWrapper = el.offsetWidth;
    const heightWrapper = el.offsetHeight;
    // Получим границы элемента с тегом body
    const bodyRect = document.body.getBoundingClientRect();
    // Получим границы элемента wrapper
    const elemRectWrapper = el.getBoundingClientRect();
    const elemRectItem = wrapperElAll.getBoundingClientRect();
    // Полуми координаты элемента wrapper по смещению
    const offsetY = elemRectItem.bottom;
    const offsetX = elemRectWrapper.right;
    // Получим элемент hint
    const hintEl = document.getElementById(`hint_${wrapperId}`);
    const wrapperEl = document.getElementById(`${wrapperId}`);
    // Получим смещение
    let shift = Number(hintEl.getAttribute('hintshift'));
    shift = !!shift || shift > -1 ? shift : 0;
    const isRadio = String(wrapperEl.getAttribute('itype')) === 'radio';
    if (!hintEl) {
      return;
    }
    // Получим высоту элемента hint
    const heightHint = hintEl.offsetHeight === 0 ? 30 : hintEl.offsetHeight;
    const heightLabel = wrapperEl.getElementsByTagName('label')[0].offsetHeight;
    const yMargin = (heightWrapper - heightHint + heightLabel) / 2;
    // Получаем стрелочку
    const arrowEl = document.getElementById(`arrow_hint_${wrapperId}`);
    // Определяем стили
    // const oldStyleEl = hintEl.getAttribute('style');
    // const oldStyleArrow = hintEl.getAttribute('style');
    let newStyleEl = '';
    let newStyleArrow = '';
    let newStyleWrapper = '';

    if (offsetX + 270 > this.screenWidth || this.screenWidth < 1750) {
      if (el.offsetTop > hintEl.offsetHeight + 10) {
        newStyleEl = `position: absolute; width: 100%; top: ${0 - heightHint}px; max-width: ${
          elemRectWrapper.width
        }px; display: block;`;
        newStyleArrow = `margin: 0 auto; bottom: -5px; left: 0px;`;
      } else {
        newStyleEl = `position: absolute; width: 100%; bottom: ${
          0 - (hintEl.offsetHeight + 10)
        }px; max-width: ${elemRectWrapper.width}px; display: block;`;
        newStyleArrow = `margin: 0 auto; top: -5px; left: 0px;`;
      }
      newStyleWrapper = `position: relative;`;
    } else {
      newStyleEl = `position: absolute; top: ${el.offsetTop + yMargin}px; left: ${
        offsetX + 20 + shift
      }px; max-width: ${this.screenWidth - (offsetX + 20)}px; display: block;`;
      newStyleArrow = `margin: auto 0; left: -5px; top: 0px;`;
      newStyleWrapper = 'position: unset;';
    }

    hintEl.setAttribute('style', newStyleEl);
    arrowEl.setAttribute('style', newStyleArrow);
    wrapperEl.setAttribute('style', newStyleWrapper);
    // if (isFirst) {
    //   isFirst = false;
    //   this.setHintStyle(addAccountWrapperEl, el, wrapperId, isFirst);
    // }
  }

  checkTemporary(): void {
    if (this.isRequestSendToTemporary) {
      return;
    }
    this.isRequestSendToDictionary = true;
    this.apiClient.auth_CheckTemporaryPassword()
      .subscribe(
        (data) => {
          if (!!data) {
            if (!this.temporaryActive) {
              this.temporaryActive = true
              const dialog = this.dialog.open(TemporaryPasswordComponent)
              dialog.afterClosed().subscribe(() => {
                this.temporaryActive = false
              })
            }
          }
        },
        (error) => {
          this.isRequestSendToDictionary = false;
          if (isExpectedError(error)) {
            this.notify.error(error.message);
          } else {
            this.notify.error(errorMessages.serverRequestError);
          }
          console.error(error);
        },
      );
  }

  getStaticDictionaries(): void {
    if (this.isRequestSendToDictionary) {
      return;
    }
    this.store.dispatch(new GetAvailableData({
      type: UserAvailableDataEnum.User
    }));
    this.isRequestSendToDictionary = true;
    forkJoin([
      this.apiClient.dictionaries_GetUserRoles().pipe(catchError((err) => of(undefined))),
      this.apiClient.dictionaries_GetCountries().pipe(catchError((err) => of(undefined))),
      this.apiClient.dictionaries_GetCurrencies().pipe(catchError((err) => of(undefined))),
      this.apiClient.dictionaries_GetServices().pipe(catchError((err) => of(undefined))),
      this.apiClient.dictionaries_GetTimeZones().pipe(catchError((err) => of(undefined))),
      this.apiClient.dictionaries_GetServicePoints().pipe(catchError((err) => of(undefined))),
    ]).subscribe(
      (data) => {
        this.store.dispatch(new SetUserRoles(data[0]));
        this.store.dispatch(new SetCountries(data[1]));
        this.store.dispatch(new SetCurrencies(data[2]));
        this.store.dispatch(new SetServices(data[3]));
        this.store.dispatch(new SetTimeZones(data[4]));
        this.store.dispatch(new SetServicePoints(data[5]));
      },
      (error) => {
        this.isRequestSendToDictionary = false;
        console.error(error);
      },
    );
  }
}
