export enum RoleProvider {
  Operator = 'Operator',
  AdministratorNabix = 'AdministratorNabix',
  SuperAdministratorNabix = 'SuperAdministratorNabix',
  AdministratorMTS = 'AdministratorMTS',
  SuperAdministratorMTS = 'SuperAdministratorMTS'
}

export enum RoleProviderRU {
  Operator = 'Оператор',
  AdministratorNabix = 'Администратор Набикс',
  SuperAdministratorNabix = 'Супер Администратор Набикс',
  AdministratorMTS = 'Администратор МТС',
  SuperAdministratorMTS = 'Супер Администратор МТС'
}
