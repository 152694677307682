import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Observable, Subject, Subscription} from "rxjs";
import {Select} from "@ngxs/store";
import {UserState} from "../../../store/state/user.state";
import {IDataSelectedHelper, IUserResponse} from "../../ApiClient";
import {DictionaryState} from "../../../store/state/dictionary.state";
import {Form, FormControl, FormGroup} from "@angular/forms";
import {debounceTime, distinctUntilChanged, filter, map, takeUntil} from "rxjs/operators";
import * as moment from 'moment';

@Component({
  selector: 'app-operations',
  templateUrl: './operations.component.html',
  styleUrls: ['./operations.component.scss']
})
export class OperationsComponent implements OnInit, OnDestroy {
  protected ngUnsubscribe: Subject<void> = new Subject<void>();
  @Select(UserState.getUser) user$: Observable<IUserResponse>;
  @Select(DictionaryState.getAvailableUsers) availableUsers$: Observable<IDataSelectedHelper[]>;
  @Select(DictionaryState.getCountries) countries$: Observable<IDataSelectedHelper[]>;
  @Select(DictionaryState.getCurrencies) currencies$: Observable<IDataSelectedHelper[]>;

  subs = new Subscription();
  availableUsers: IDataSelectedHelper[] = [];
  countries: IDataSelectedHelper[] = [];
  currencies: IDataSelectedHelper[] = [];

  downloadInProgress = true;
  filterExpand = false;
  submitted = true;
  dateFromMin: Date;
  dateFromMax: Date;
  dateToMin: Date;
  dateToMax: Date;

  operationFilterForm: FormGroup;
  transferNumber: FormControl;
  operatorId: FormControl;
  country: FormControl;
  status: FormControl;
  sumFrom: FormControl;
  sumTo: FormControl;
  dateFrom: FormControl;
  dateTo: FormControl;
  paymentSystemId: FormControl;
  acceptedCurrency: FormControl;
  withdrawCurrency: FormControl;

  constructor() {
    this.defaultMinMaxDate();
  }

  get isNeedFilterReset(): boolean {
    return !!this.operationFilterForm &&
      (!!this.operationFilterForm.value.TransferNumber ||
        !!this.operationFilterForm.value.OperatorId ||
        !!this.operationFilterForm.value.Country ||
        !!this.operationFilterForm.value.Status ||
        !!this.operationFilterForm.value.SumFrom ||
        !!this.operationFilterForm.value.SumTo ||
        !!this.operationFilterForm.value.DateFrom ||
        !!this.operationFilterForm.value.DateTo ||
        !!this.operationFilterForm.value.PaymentSystemId ||
        !!this.operationFilterForm.value.AcceptedCurrency ||
        !!this.operationFilterForm.value.WithdrawCurrency);
  }

  ngOnInit(): void {
    this.subs.add(this.availableUsers$.subscribe(availableUsers => {
      this.availableUsers = availableUsers;
    }));
    this.subs.add(this.countries$.subscribe(countries => {
      this.countries = countries;
    }));
    this.subs.add(this.currencies$.subscribe(currencies => {
      this.currencies = currencies;
    }));
    this.createFormControls();
  }

  defaultMinMaxDate(): void {
    this.dateFromMin = new Date(1900, 0, 0);
    const temp = moment();
    this.dateFromMax = new Date(temp.year(), temp.month(), temp.date());
    this.dateToMin = new Date(1900, 0, 0);
    this.dateToMax = new Date(2100, 0, 0);
  }

  createFormControls(): void {
    this.transferNumber = new FormControl(null);
    this.operatorId = new FormControl(null);
    this.country = new FormControl(null);
    this.status = new FormControl(null);
    this.sumFrom = new FormControl(null);
    this.sumTo = new FormControl(null);
    this.dateFrom = new FormControl(null);
    this.dateTo = new FormControl(null);
    this.paymentSystemId = new FormControl(null);
    this.acceptedCurrency = new FormControl(null);
    this.withdrawCurrency = new FormControl(null);

    this.operationFilterForm = new FormGroup({
      TransferNumber: this.transferNumber,
      OperatorId: this.operatorId,
      Country: this.country,
      Status: this.status,
      SumFrom: this.sumFrom,
      SumTo: this.sumTo,
      DateFrom: this.dateFrom,
      DateTo: this.dateTo,
      PaymentSystemId: this.paymentSystemId,
      AcceptedCurrency: this.acceptedCurrency,
      WithdrawCurrency: this.withdrawCurrency
    });
    this.valueChanges();
  }

  resetAll(): void {
    Object.keys(this.operationFilterForm.controls).forEach(name => {
      if (!!this.operationFilterForm.get(name)) {
        this.operationFilterForm.get(name).setValue(null);
      }
    });
    this.defaultMinMaxDate();
    this.getFilteredOperations();
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  valueChanges(): void {
    this.operationFilterForm.valueChanges
      .pipe(
        debounceTime(500),
        filter((d: any) => d.SumFrom || d.SumTo),
        map((d) => ({
          SumFrom: d.SumFrom,
          SumTo: d.SumTo
        })),
        distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe((d) => {
        if (!d.SumFrom && !!d.SumTo) {
          this.operationFilterForm.controls.SumFrom.setValue(0);
        }
        if (d.SumFrom >= d.SumTo && !!d.SumTo) {
          this.operationFilterForm.controls.SumTo.setValue(Number(d.SumFrom) + 1);
        }
      });
    this.operationFilterForm.valueChanges
      .pipe(
        debounceTime(500),
        filter((d: any) => d.DateFrom || d.DateTo),
        map((d) => ({
          DateFrom: d.DateFrom,
          DateTo: d.DateTo
        })),
        distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe((d) => {
        this.dateToMin = !!d.DateFrom ?
          new Date(d.DateFrom.year(), d.DateFrom.month(), d.DateFrom.date() + 1) :
          new Date(1900, 0, 0);
        const temp = moment();
        this.dateFromMax = !!d.DateTo ? new Date(d.DateTo.year(), d.DateTo.month(), d.DateTo.date() - 1) :
          new Date(temp.year(), temp.month(), temp.date());
      });
  }

  getFilteredOperations(): void {

  }

  download(): void {

  }
}
