<div class="left-menu-wrapper" [ngClass]="{'open': leftMenuOpen}">
  <div class="left-menu">
    <div class="left-menu-profile-wrapper">
      <div class="left-menu-profile-img-wrapper">
        <img [src]="avatar" alt="user">
      </div>
      <div class="left-menu-profile-info-wrapper" *ngIf="!!user">
        <p class="left-menu-profile-name">{{ user.lastName }} {{ user.firstName }}</p>
        <p class="left-menu-profile-role mb-10">{{ user.roleName | rolePipe }}</p>
        <p class="left-menu-profile-phone">{{ user.phone | phonePipe }}</p>
      </div>
    </div>
    <div class="left-menu-items-wrapper">
      <ul class="left-menu-items">
        <li *ngFor="let item of navs; let i=index; trackBy:trackByFn"
            class="left-menu-item"
            [routerLink]="item.link" (click)="goto(item.link)"
            routerLinkActive="active">
          <p>{{ item.name }}</p>
        </li>
      </ul>
    </div>
    <div class="left-menu-footer-wrapper t-align-center mt-40">
      <img class="left-menu-footer-logo" [src]="logos.usual" alt="Nabix"/>
      <p class="footer-info">
        Made by &copy; NABIX, {{ year }} <a href="https://nabix.ru" target="_blank" class="download-title">NABIX.ru</a>
      </p>
      <p>
        <small>
          <button class="button unfilled" (click)="onLogout()">Выйти</button>
        </small><br>
      </p>
    </div>
  </div>
</div>
