import { UserState } from 'src/store/state/user.state';
import {Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild} from '@angular/core';
import { Observable, Subject, Subscription } from 'rxjs';
import {Select, Store} from '@ngxs/store';
import {IUserResponse} from "../../../ApiClient";
import {navItems} from "../../../../constants/common.constants";
import {Navigate} from "@ngxs/router-plugin";

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {
  protected ngUnsubscribe: Subject<void> = new Subject<void>();
  @Select(UserState.isAuthorized) isAuthorized$: Observable<boolean>;
  @Select(UserState.getUser) user$: Observable<IUserResponse>;

  subs = new Subscription();

  avatar = '/assets/img/nav/avatar.svg';

  leftMenuOpen = false;

  user: IUserResponse;
  isAuthorized = false;
  navs = [];

  @ViewChild('leftMenu', { static: false }) leftMenu: ElementRef;
  @ViewChild('hamburger', { static: false }) hamburger: ElementRef;
  @HostListener('document:click', ['$event.target'])
  private clickOutside(targetElement) {
    const inside = !!this.leftMenu && this.leftMenu.nativeElement.contains(targetElement) ||
      !!this.hamburger && this.hamburger.nativeElement.contains(targetElement);
    if (!inside) {
      this.leftMenuOpen = false;
    }
  }

  constructor(
    private store: Store
  ) { }

  ngOnInit() {
    this.subs.add(this.isAuthorized$.subscribe(isAuthorized => { this.isAuthorized = isAuthorized }));
    this.subs.add(this.user$.subscribe(user => {
      this.user = user
      if (!!user) {
        this.navs = []
        const role = user.roleName
        navItems.map(x => {
          if (x.roles && x.roles.length > 0 && x.roles.findIndex(y => y === role) > -1 || x.roles.length === 0) {
            this.navs.push(x)
          }
        })
      }
    }))
  }

  goto(url: string): void {
    this.store.dispatch(new Navigate([`/${url}`]))
    return
  }

  ngOnDestroy() {
    this.subs.unsubscribe()
    this.ngUnsubscribe.next()
    this.ngUnsubscribe.complete()
  }

  trackByFn(i, item) {
    return item.id
  }
}
