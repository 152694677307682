import { animate, query, stagger, style, transition, trigger } from '@angular/animations';

const translate = 20;
const duration = 300;
const durationFast = 150;

const opacityUp = [style({ opacity: 0 }), animate(duration, style({ opacity: 1 }))];

const opacityDown = [style({ opacity: 1 }), animate(duration, style({ opacity: 0 }))];

const enterXLeft = [
  style({ opacity: 0, transform: `translateX(-${translate}%)` }),
  animate(duration, style({ opacity: 1, transform: 'translateX(0)' })),
];

const enterXRight = [
  style({ opacity: 0, transform: `translateX(${translate}%)` }),
  animate(durationFast, style({ opacity: 1, transform: 'translateX(0)' })),
];

const enterY = [
  style({ opacity: 0, transform: `translateY(-${translate}%)` }),
  animate(durationFast, style({ opacity: 1, transform: 'translateY(0)' })),
];

export const fadeOpacity = trigger('fadeOpacity', [
  transition(':enter', opacityUp),
  transition(':leave', opacityDown),
]);

export const fadeInOut = trigger('fadeInOut', [
  transition(':enter', enterXLeft),
  transition(':leave', [
    animate(duration, style({ opacity: 0, transform: `translateX(${translate}%)` })),
  ]),
]);

export const fadeInDown = trigger('fadeInDown', [
  transition(':enter', enterY),
  transition(':leave', [
    animate(durationFast, style({ opacity: 0, transform: `translateY(-${translate}%)` })),
  ]),
]);

export const fadeInRight = trigger('fadeInRight', [
  transition(':enter', enterXRight),
  transition(':leave', [
    animate(durationFast, style({ opacity: 0, transform: `translateX(${translate}%)` })),
  ]),
]);

export const fadeIn = trigger('fadeIn', [transition(':enter', enterXLeft)]);

export const fadeInOutStagged = trigger('fadeInOutStagged', [
  transition(':enter', [
    query('*', [
      style({ opacity: 0, transform: `translateX(-${translate}%)` }),
      stagger(6, [animate(duration, style({ opacity: 1, transform: 'translateX(0)' }))]),
    ]),
  ]),
]);

export const fadeInOutUpStagged = trigger('fadeInOutUpStagged', [
  transition(':enter', [
    query(
      '*',
      [
        style({ opacity: 0, transform: `translateY(-${translate}%)` }),
        stagger(6, [animate(200, style({ opacity: 1, transform: 'translateY(0)' }))]),
      ],
      { optional: true },
    ),
  ]),
]);

export const fadeInOutUp = trigger('fadeInOutUp', [
  transition(':enter', [
    style({ opacity: 0, transform: `translateY(-${translate}%)` }),
    animate(duration / 2, style({ opacity: 1, transform: 'translateY(0)' })),
  ]),
  transition(':leave', [
    animate(duration / 2, style({ opacity: 0, transform: `translateY(${translate}%)` })),
  ]),
]);
