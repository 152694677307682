import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'phonePipe'
})
export class PhonePipe implements PipeTransform {

  transform(phone: any): any {
    if (phone && phone.length === 12) {
      phone = phone.substring(0, 2) + '(' +
        phone.substring(2, 5) + ')' +
        phone.substring(5, 8) + '-' +
        phone.substring(8, 10) + '-' +
        phone.substring(10, 12)
    }
    return phone
  }
}
