import {Component, OnDestroy, OnInit} from '@angular/core';
import * as moment from 'moment';
import {ApiClient, Client, IUserResponse} from "../../../ApiClient";
import {Subject} from "rxjs";
import {Store} from "@ngxs/store";
import {takeUntil} from "rxjs/operators";
import {LoginRedirect, ResetUser, SetUser} from "../../../../store/actions/user.actions";
import {isExpectedError} from "../../../../core/helpers/data.helper";
import {errorMessages} from "../../../../constants/errors";
import {NotifyService} from "../../../../services/notify.service";

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit, OnDestroy {
  protected ngUnsubscribe: Subject<void> = new Subject<void>();
  year: number

  user: IUserResponse;
  logos = {
    usual: '/assets/img/nav/nabix-logo-big.svg',
    newYear: '/assets/img/nav/nabix-new-year-logo-small.svg',
  }

  constructor(
    private apiClient: ApiClient,
    private store: Store,
    private notify: NotifyService
  ) {
    this.year = moment().year();
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  onLogout(): void {
    this.apiClient.auth_Logout()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (data) => {
          this.store.dispatch(new ResetUser());
        },
        (error) => {
          if (isExpectedError(error)) {
            this.notify.error(error.message);
          } else {
            this.notify.error(errorMessages.serverRequestError);
          }
          console.error(error);
        },
      );
  }

}
