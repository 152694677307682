import {Component, OnDestroy, OnInit} from '@angular/core';
import {Store} from "@ngxs/store";
import {NotifyService} from "../../../services/notify.service";
import {Subject, Subscription} from "rxjs";
import {BehaviorService} from "../../../services/behavior.service";

@Component({
  selector: 'app-support',
  templateUrl: './support.component.html',
  styleUrls: ['./support.component.scss']
})
export class SupportComponent implements OnInit, OnDestroy {
  protected ngUnsubscribe: Subject<void> = new Subject<void>()
  subs = new Subscription()

  phoneArr = []

  constructor(
    private notify: NotifyService,
    private store: Store,
    private behavior: BehaviorService
  ) { }

  ngOnInit(): void {
    this.phoneArr = [];
    this.phoneArr.push('+74959091508');
    this.behavior.initCenterStatus.next(true);
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}

