import { Pipe, PipeTransform } from '@angular/core';
import {RoleProvider, RoleProviderRU} from "../../enum/role-provider.enum";
import {getUserRoleRu} from "../helpers/data.helper";

@Pipe({
  name: 'rolePipe'
})
export class RolePipe implements PipeTransform {

  transform(role: any): any {
    return getUserRoleRu(role);
  }
}
