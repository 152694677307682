import { Injectable } from '@angular/core'
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http'
import { Observable, throwError } from 'rxjs'
import { map, catchError, finalize, share } from 'rxjs/operators'
import { Store } from '@ngxs/store'
import { Router } from '@angular/router'
import {LoginRedirect, ResetUser} from '../../store/actions/user.actions'
import { UserState } from '../../store/state/user.state'
import { notNullOrUndefined } from '../helpers/data.helper'

export const fileDataAcceptUrls = [
];

export const HTTP_METHODS = {
  GET: 'GET',
  HEAD: 'HEAD',
  POST: 'POST',
  PUT: 'PUT',
  PATCH: 'PATCH',
  DELETE: 'DELETE',
  CONNECT: 'CONNECT',
  OPTIONS: 'OPTIONS',
  TRACE: 'TRACE'
};


@Injectable()
export class BaseUrlInterceptor implements HttpInterceptor {

  constructor(
    private router: Router,
    private store: Store) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if ((request.method === HTTP_METHODS.POST ||
      request.method === HTTP_METHODS.PUT) &&
      !notNullOrUndefined(request.body)) {
      return;
    }
    const token = UserState.localRememberMeStatus ? UserState.localToken : UserState.sessionToken || '';
    const apiReq = this.getMainReq(request, request.url, token);
    return next.handle(apiReq).pipe( catchError((error: HttpErrorCustomResponse) => {
      if (error.status === 401 && !location.href.includes('/auth/')) {
        sessionStorage.removeItem('sessionToken');
        // this.router.navigate(['auth/login']);
        this.store.dispatch(new ResetUser());
      }
      else {
        if (typeof error === 'string') {
          try {
            error = JSON.parse(error);
          } catch { }
        }
        if (error.status === 401) {
          return this.store.dispatch(new ResetUser());
        }
        return throwError(error)
      }
    })
      // finalize(() => {
      //   // this.store.dispatch(new EndLoading({ key }))
      // })
    )
  }

  private handleError(error: HttpErrorCustomResponse) {
    if (error.status === 401 && !location.href.includes('/auth/')) {
      sessionStorage.removeItem('sessionToken');
      // this.router.navigate(['auth/login']);
      return this.store.dispatch(new ResetUser());
    }
    else {
      if (typeof error === 'string') {
        try {
          error = JSON.parse(error);
        } catch { }
      }
      if (error.status === 401) {
        return this.store.dispatch(new ResetUser());
      }
      return throwError(error)
    }
  }



  getServiceReq(request: HttpRequest<any>, url: string): HttpRequest<any> {
    const apiReq = request.clone({
      setHeaders: {
        accept: 'application/json',
        // 'Content-Type': 'application/json',
        'Cache-Control': 'public, max-age=691200, s-maxage=691200',
        Pragma: 'public'
      },
      url
    })
    return apiReq
  }

  // TODO SEE Bearer JwtMTS
  getMainReq(request: HttpRequest<any>, url: string, token: any): HttpRequest<any> {
    if (request.url.includes('ahunter') || request.url.includes('bik-info.ru')) {
      return request;
    }
    const indexFileAccept = fileDataAcceptUrls.findIndex(x => x === request.url);
    const apiReq = request.clone({
      setHeaders: indexFileAccept > -1 ?
        {
          Authorization: token ? `JwtMTS ${token}` : token,
          accept: 'application/json',
          'Cache-Control': 'public, max-age=691200, s-maxage=691200',
          Pragma: 'public'
        } : {
          Authorization: token ? `JwtMTS ${token}` : token,
          accept: 'application/json',
          // 'Content-Type': 'application/json',
          'Cache-Control': 'public, max-age=691200, s-maxage=691200',
          Pragma: 'public'
        },
      url
    })
    return apiReq
  }
}

export declare class HttpErrorCustomResponse extends HttpErrorResponse {
  fields: any
}

