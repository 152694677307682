import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {messages} from "../../../constants/messages";
import {NotifyService} from "../../../services/notify.service";
import {debounceTime, distinctUntilChanged, filter, map, takeUntil} from "rxjs/operators";
import {Subject} from "rxjs";
import {
  ApiClient, ChangePasswordRequest,
  IChangePasswordRequest,
  IUserFilterRequest,
  PaginatorRequest,
  UserRestorePasswordRequest
} from "../../ApiClient";
import {errorMessages} from "../../../constants/errors";
import {isExpectedError, mapInvalidFields} from "../../../core/helpers/data.helper";

@Component({
  selector: 'app-temporary-password',
  templateUrl: './temporary-password.component.html',
  styleUrls: ['./temporary-password.component.scss']
})
export class TemporaryPasswordComponent implements OnInit, OnDestroy {
  protected ngUnsubscribe: Subject<void> = new Subject<void>();
  passwordForm: FormGroup;
  oldPassword: FormControl;
  newPassword: FormControl;
  repeatNewPassword: FormControl;

  submitted = false;

  constructor(
    public dialogRef: MatDialogRef<TemporaryPasswordComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {},
    private apiClient: ApiClient,
    private notify: NotifyService
  ) {}

  get isTemporaryCorrect(): boolean {
    return !!this.passwordForm &&
      !this.passwordForm.controls.OldPassword.errors &&
      this.isNewPasswordCompared;
  }

  get isNewPasswordCompared(): boolean {
    return !!this.passwordForm &&
      (!!this.passwordForm.value.NewPassword && !!this.passwordForm.value.RepeatNewPassword &&
        this.passwordForm.value.NewPassword === this.passwordForm.value.RepeatNewPassword ||
        !this.passwordForm.value.NewPassword && !this.passwordForm.value.RepeatNewPassword)
  }


  ngOnInit() {
    this.createFormControl()
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next()
    this.ngUnsubscribe.complete()
  }

  createFormControl(): void {
    this.oldPassword = new FormControl(null, [Validators.required])
    this.newPassword = new FormControl(null, [Validators.required])
    this.repeatNewPassword = new FormControl(null, [Validators.required])
    this.passwordForm = new FormGroup({
      OldPassword: this.oldPassword,
      NewPassword: this.newPassword,
      RepeatNewPassword: this.repeatNewPassword
    });

    this.passwordForm.valueChanges
      .pipe(
        debounceTime(100),
        filter((d: any) => d.NewPassword && d.RepeatNewPassword),
        map((d) => ({
          newPassword: d.NewPassword,
          repeatNewPassword: d.RepeatNewPassword
        })),
        distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe((d) => {
        this.passwordForm.controls.NewPassword.setErrors(!!d.newPassword && !!d.repeatNewPassword &&
        d.newPassword !== d.repeatNewPassword ? {pwdDontMatch : true} : null);
      });
  }

  onTemporary(): void {
    this.submitted = true;
    if (!this.isTemporaryCorrect) {
      this.notify.error(errorMessages.fillAllFieldsToContinue);
      return;
    }
    const payload: IChangePasswordRequest = {
      oldPassword: this.passwordForm.value.OldPassword,
      newPassword: this.passwordForm.value.NewPassword,
      confirmPassword: this.passwordForm.value.RepeatNewPassword
    };

    this.apiClient.auth_ChangePassword(payload as ChangePasswordRequest)
      .subscribe(
        (data) => {
          this.notify.success(messages.passwordChanged);
          this.close();
        },
        (error) => {
          if (isExpectedError(error)) {
            this.passwordForm = mapInvalidFields(this.passwordForm, error.invalidFields);
            this.notify.error(error.message);
          } else {
            this.notify.error(errorMessages.serverRequestError);
          }
          console.error(error);
        },
      );
  }

  close(): void {
    this.dialogRef.close()
  }

}
