<div>
  <div *ngIf="visible" class="modal-background" [ngClass]="{'modal-background_visible':visible}" role="dialog">
    <div class="modal">
      <div class="modal-header"  [ngClass]="{'pb-0': !!user}">
        <div class="modal-title">{{user ? 'Обновление данных' : 'Создание сотрудника'}}</div>
        <img class="modal-button-close" src="/assets/img/icons/close.svg" (click)="toggleModal(null)"/>
      </div>
      <div class="modal-subtitle" *ngIf="user">{{user.lastName}} {{user.firstName}} {{user.patronymic}}</div>
      <div>
        <ng-container *ngIf="!success">
          <div
            (click)="typeWindow = 0"
            [style.padding-left.px]="24"
            [ngClass]="{ 'color-main': typeWindow === 0, 'opacity-0-5': typeWindow !== 0 }"
            class="font-b pointer d-inline-block"
          >Основные данные</div>
        </ng-container>
      </div>
      <div class="modal-wrapper" *ngIf="userForm">
        <form *ngIf="!success" [formGroup]="userForm">
          <div *ngIf="typeWindow === 0">

            <app-input inputType="text"
                       placeholder="Фамилия сотрудника"
                       label="Фамилия сотрудника"
                       name="LastName"
                       width="full"
                       wrapperClass="inputBox-group d-inline-block w-48 mr-2"
                       [controlInvalid]="submitted"
                       [parentForm]="userForm"></app-input>

            <app-input inputType="text"
                       placeholder="Имя сотрудника"
                       label="Имя сотрудника"
                       name="FirstName"
                       width="full"
                       wrapperClass="inputBox-group d-inline-block w-48 ml-2"
                       [controlInvalid]="submitted"
                       [parentForm]="userForm"></app-input>

            <app-input inputType="text"
                       placeholder="Отчество сотрудника"
                       label="Отчество сотрудника"
                       name="Patronymic"
                       width="full"
                       wrapperClass="inputBox-group d-inline-block w-48 mr-2"
                       [controlInvalid]="submitted"
                       [parentForm]="userForm"></app-input>

            <app-input inputType="text"
                       placeholder="000000000000"
                       label="ИНН"
                       name="Inn"
                       width="full"
                       wrapperClass="inputBox-group d-inline-block w-48 ml-2"
                       [maxLength]="12"
                       [controlInvalid]="submitted"
                       [parentForm]="userForm"></app-input>

            <app-input [inputType]="'select'"
                       [controlInvalid]="submitted" [onlySelect]="true"
                       [placeholder]="'Роль сотрудника'" [label]="'Роль сотрудника'"
                       name="Role" wrapperClass="inputBox-group d-inline-block w-48 mr-2"
                       [contentMaxHeight]="600" [selectData]="availableUserRoles || []"
                       [parentForm]="userForm"></app-input>

            <app-input [inputType]="'select'"
                       [controlInvalid]="submitted"
                       [placeholder]="'Часовой пояс'" [label]="'Часовой пояс'"
                       name="TimeZoneId" wrapperClass="inputBox-group d-inline-block w-48 ml-2"
                       [contentMaxHeight]="600" [selectData]="availableTimeZones || []"
                       [parentForm]="userForm"></app-input>

            <app-input inputType="text"
                       placeholder="Номер телефона"
                       label="Номер телефона"
                       name="Phone"
                       width="full"
                       wrapperClass="inputBox-group d-inline-block w-48 mr-2"
                       maskPrefix="+7"
                       mask="(000)-000-0000"
                       [controlInvalid]="submitted"
                       [parentForm]="userForm"></app-input>

            <app-input inputType="text"
                       placeholder="Email"
                       label="Email"
                       name="Email"
                       width="full"
                       wrapperClass="inputBox-group d-inline-block w-48 ml-2"
                       [controlInvalid]="submitted"
                       [parentForm]="userForm"></app-input>



            <app-checkbox
              [wrapperClass]="'inputBox-group'"
              *ngIf="user"
              [parentForm]="userForm"
              name="IsBlocked"
              label="Заблокировать сотрудника"
              [controlInvalid]="submitted"
              (stateChange)="changeBlock($event)"
            ></app-checkbox>


            <div class="modal-button-wrapper t-align-center mt-50">

              <button class="button border" [@fadeInOut] [style.margin.px]="10"
                      (click)="toggleModal(null)">Отменить</button>
              <button class="button main" [style.margin.px]="10"
                      [ngClass]="{'disabled': !!userForm.invalid}"
                      (click)="arrOrUpdateUser()"
                      [@fadeInOut]>{{user ? 'Обновить' : 'Создать'}}</button>

              <div
                *ngIf="!success && user && (isAdministratorNabix || isSuperAdministratorNabix)"
                (click)="resetPasswordFromAdmin()"
                class="download-title mt-20"
              >Сбросить пароль</div>
            </div>
          </div>

        </form>

        <div class="t-align-center" *ngIf="success">

          <img class="statusIco" src="/assets/img/icons/check-green.svg">
          <p class="pass-info">Временный пароль был отправлен на указанный email</p>
          <div class="user-message"><p class="user-message-info">Логин:</p> <p class="user-message-item">{{login | phonePipe}}</p></div>
          <div class="user-message" [style.margin-bottom.px]="40"><p class="user-message-info">Пароль:</p> <p class="user-message-item">{{message}}</p></div>

          <div class="modal-button-wrapper t-align-center">
            <button class="button main" [style.margin-left.px]="20"
                    (click)="toggleModal(null)"
                    [@fadeInOut]>Закрыть</button>
          </div>
        </div>
      </div>
      <div>
      </div>
    </div>
  </div>
</div>
