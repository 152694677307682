<div [class]="wrapperClass" [id]="wrapperId" itype="checkbox" (mouseenter)="checkHint()" [style.margin-left.px]="2" [ngClass]="{'input-drop': readonly}">
  <div class="checkboxInput-wrapper">
    <span *ngIf="isReactive" [formGroup]="parentForm">
      <input
        class="checkboxInput"
        [id]="id"
        [formControlName]="name"
        (change)="dataChange()"
        type="checkbox"
        [attr.disabled]="readonly ? true: null"
      >
      <label [for]="id" class="checkboxLabel">
        {{ label }}
      </label>
    </span>
    <span *ngIf="!isReactive">
      <input
        class="checkboxInput"
        [ngClass]="{'invalid': setInvalidClass}"
        [id]="id"
        [(ngModel)]="state"
        (change)="dataChange()"
        type="checkbox"
        [attr.disabled]="readonly ? true: null"
      >
      <label [for]="id" class="checkboxLabel">
        {{ label }}
      </label>
    </span>
    <div class="hint-bottom t-align-left" *ngIf="!!hintBottom">{{ hintBottom }}</div>
    </div>

    <app-control-validator [parentForm]="parentForm" [name]="name" [showErrors]="controlInvalid" [nowrap]="nowrap" [fromComponent]="'checkbox'"></app-control-validator>

    <div class="input-hint" [id]="'hint_' + wrapperId" [attr.hintShift]="hintShift" *ngIf="!!hint">
      <div class="input-hint-container">
        <span class="hint-arrow" [id]="'arrow_hint_' + wrapperId"></span>
        <span class="input-hint-text">{{ hint }}</span>
      </div>
      </div>
    </div>
