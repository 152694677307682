import {Component, OnDestroy, OnInit} from '@angular/core';
import {Observable, Subject, Subscription} from "rxjs";
import {Guid} from "guid-typescript";
import {
  ApiClient,
  IPaginatorRequest,
  IUserFilterRequest,
  IUserFilterResponse, IUserResponse, IUserSettingsRequest, PaginatorRequest,
  UserFilterEnum, UserFilterRequest, UserSettingsRequest
} from "../../../../ApiClient";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {NotifyService} from "../../../../../services/notify.service";
import {Select, Store} from "@ngxs/store";
import {createPaginator} from "../../../../../core/helpers/paginator.helper";
import {debounceTime, distinctUntilChanged, filter, map, takeUntil} from "rxjs/operators";
import {UserState} from "../../../../../store/state/user.state";
import {ResetUser, SetUser} from "../../../../../store/actions/user.actions";
import {isExpectedError, mapInvalidFields} from "../../../../../core/helpers/data.helper";
import {errorMessages} from "../../../../../constants/errors";
import {messages} from "../../../../../constants/messages";

@Component({
  selector: 'app-user-settings',
  templateUrl: './user-settings.component.html',
  styleUrls: ['./user-settings.component.scss']
})
export class UserSettingsComponent implements OnInit, OnDestroy {
  protected ngUnsubscribe: Subject<void> = new Subject<void>()
  @Select(UserState.getUser) user$: Observable<IUserResponse>;

  subs = new Subscription();
  user: IUserResponse;

  expanded = false;
  submitted = false;
  isFirst = true;
  valueChangeFirst = true;
  isLoad = false;
  filterExpand = false;

  settingForm: FormGroup;
  oldPassword: FormControl;
  newPassword: FormControl;
  reNewPassword: FormControl;

  constructor(
    private notify: NotifyService,
    private apiClient: ApiClient,
    private store: Store
  ) { }

  get isNewPasswordCompared(): boolean {
    return !!this.settingForm &&
      (!!this.settingForm.value.NewPassword && !!this.settingForm.value.ReNewPassword &&
      this.settingForm.value.NewPassword === this.settingForm.value.ReNewPassword ||
        !this.settingForm.value.NewPassword && !this.settingForm.value.ReNewPassword)
  }

  ngOnInit() {
    this.createFormControls();
    this.subs.add(this.user$.subscribe(user => {
      this.user = user;
      this.createFormControls();
    }));
  }

  createFormControls(): void {
    if (!!this.settingForm) {
      return;
    }

    this.oldPassword = new FormControl(null, [Validators.required]);
    this.newPassword = new FormControl(null);
    this.reNewPassword = new FormControl(null);

    this.settingForm = new FormGroup({
      OldPassword: this.oldPassword,
      NewPassword: this.newPassword,
      ReNewPassword: this.reNewPassword
    });
    this.settingForm.valueChanges
      .pipe(
        debounceTime(100),
        filter((d: any) => d.NewPassword && d.ReNewPassword),
        map((d) => ({
          newPassword: d.NewPassword,
          reNewPassword: d.ReNewPassword
        })),
        distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe((d) => {
        this.settingForm.controls.NewPassword.setErrors(!!d.newPassword && !!d.reNewPassword &&
          d.newPassword !== d.reNewPassword ? {pwdDontMatch : true} : null);
      });
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  toggleExpand(): void {
    this.expanded = !this.expanded
    if (this.expanded && this.isFirst) {
      this.isFirst = false;
    }
  }

  updateUserInfo(): void {
    this.submitted = true;
    if (!!this.settingForm.invalid || !this.isNewPasswordCompared) {
      return;
    }
    const payload: IUserSettingsRequest = {
      oldPassword: this.settingForm.value.OldPassword,
      newPassword: this.settingForm.value.NewPassword,
      reNewPassword: this.settingForm.value.ReNewPassword
    };
    this.apiClient.users_UpdateUserSettings(payload as UserSettingsRequest)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (data) => {
          this.store.dispatch(new SetUser(data.user));
          this.notify.success(messages.settingsUpdated);
        },
        (error) => {
            if (isExpectedError(error)) {
              this.settingForm = mapInvalidFields(this.settingForm, error.invalidFields);
              this.notify.error(error.message);
          } else {
            this.notify.error(errorMessages.serverRequestError);
          }
          console.error(error);
        },
      );

  }
}
