import {Component, OnDestroy, OnInit} from '@angular/core';
import {Observable, Subject, Subscription} from "rxjs";
import {Select} from "@ngxs/store";
import {UserState} from "../../../store/state/user.state";

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, OnDestroy {
  protected ngUnsubscribe: Subject<void> = new Subject<void>();
  @Select(UserState.isAuthorized) isAuthorized$: Observable<boolean>;
  subs = new Subscription();
  isAuthorized = false;
  constructor() { }

  ngOnInit(): void {
    this.subs.add(this.isAuthorized$.subscribe(isAuthorized => { this.isAuthorized = isAuthorized }));
  }

  ngOnDestroy() {
    this.subs.unsubscribe()
    this.ngUnsubscribe.next()
    this.ngUnsubscribe.complete()
  }
}
