export const evnTypeProvider = {
  dev: 'dev',
  local: 'local',
  prod: 'prod',
  stage: 'stage'
};

export const digits = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];
export const digitsStr = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];

export const inputTypes = {
  tel: 'tel',
  search: 'search',
  select: 'select',
  datepicker: 'datepicker',
  stringdate: 'stringdate',
  card: 'card',
  number: 'number',
  text: 'text',
  password: 'password',
  textarea: 'textarea',
  checkbox: 'checkbox',
  radio: 'radio',
};

export const keyBoardRus = `йцукенгшщзхъфывапролджэ\\ячсмитьбю.ЙЦУКЕНГШЩЗХЪФЫВАПРОЛДЖЭ\ЯЧСМИТЬБЮ.ЙЦУКЕНГШЩЗХЪФЫВАПРОЛДЖЭ/ЯЧСМИТЬБЮ;!"№;%:?*()_+`;
export const keyBoardEng = `qwertyuiop[]asdfghjkl;'\\zxcvbnm;./QWERTYUIOP[]ASDFGHJKL;'\ZXCVBNM;./QWERTYUIOP{}ASDFGHJKL:"|ZXCVBNM<>?!@#$%^&*()_+`;

export const navItems = [
  {
    name: 'Главная',
    link: 'home',
    roles: []
  },
  {
    name: 'Операции',
    link: 'operations',
    roles: []
  },
  {
    name: 'Техподдержка',
    link: 'support',
    roles: []
  },
  {
    name: 'Настройки',
    link: 'settings',
    roles: []
  }
]
