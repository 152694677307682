import {Routes, RouterModule} from '@angular/router';
import {NgModule} from '@angular/core';
import {AuthComponent} from './pages/auth/auth.component';
import {PersonalAreaComponent} from "./pages/personal-area/personal-area.component";
import {AuthGuard} from "../core/guards/auth.guard";
import {HomeComponent} from "./pages/home/home.component";
import {OperationsComponent} from "./pages/operations/operations.component";
import {SupportComponent} from "./pages/support/support.component";
import {SettingsComponent} from "./pages/settings/settings.component";

const routes: Routes = [
  {
    path: '',
    component: PersonalAreaComponent,
    children: [
      {path: '', pathMatch: 'full', redirectTo: 'home'},
      {path: 'home', component: HomeComponent, canActivate: [AuthGuard]},
      {path: 'operations', component: OperationsComponent, canActivate: [AuthGuard]},
      {path: 'support', component: SupportComponent, canActivate: [AuthGuard]},
      {path: 'settings', component: SettingsComponent, canActivate: [AuthGuard]},
      //{path: '**', pathMatch: 'full', redirectTo: 'home'}
    ]
  },
  {path: 'auth', pathMatch: 'full', redirectTo: 'auth/login'},
  {path: 'auth/login', component: AuthComponent},
  // { path: 'auth/restore', component: AuthComponent },
  { path: 'auth/forgot', component: AuthComponent },
  { path: 'auth/restore', component: AuthComponent },
  { path: 'auth/restore/:url', component: AuthComponent },
  // { path: 'auth/reset', component: AuthComponent },
  // { path: 'auth/register', component: AuthComponent },
  {path: '**', pathMatch: 'full', redirectTo: 'auth'}
]

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {relativeLinkResolution: 'legacy'})
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
