import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {Observable, Subject, Subscription} from "rxjs";
import {Select, Store} from "@ngxs/store";
import {UserState} from "../../../../store/state/user.state";
import {ApiClient, IUserResponse} from "../../../ApiClient";
import {navItems} from "../../../../constants/common.constants";
import {Navigate} from "@ngxs/router-plugin";
import {takeUntil} from "rxjs/operators";
import {ResetUser} from "../../../../store/actions/user.actions";
import {isExpectedError} from "../../../../core/helpers/data.helper";
import {errorMessages} from "../../../../constants/errors";
import {NotifyService} from "../../../../services/notify.service";
import * as moment from "moment";

@Component({
  selector: 'app-left-menu',
  templateUrl: './left-menu.component.html',
  styleUrls: ['./left-menu.component.scss']
})
export class LeftMenuComponent implements OnInit, OnDestroy {
  protected ngUnsubscribe: Subject<void> = new Subject<void>();
  @Input() leftMenuOpen = false;
  @Output() close = new EventEmitter<null>();

  @Select(UserState.isAuthorized) isAuthorized$: Observable<boolean>;
  @Select(UserState.getUser) user$: Observable<IUserResponse>;

  subs = new Subscription();
  isAuthorized = false;
  user: IUserResponse;
  avatar = '/assets/img/nav/avatar.svg';
  navs = [];
  year: number
  logos = {
    usual: '/assets/img/nav/nabix-logo-big.svg',
    newYear: '/assets/img/nav/nabix-new-year-logo-small.svg',
  }

  constructor(
    private store: Store,
    private notify: NotifyService,
    private apiClient: ApiClient
  ) { }

  ngOnInit() {
    this.year = moment().year();
    this.subs.add(this.isAuthorized$.subscribe(isAuthorized => { this.isAuthorized = isAuthorized }));
    this.subs.add(this.user$.subscribe(user => {
      this.user = user
      if (!!user) {
        this.navs = []
        const role = user.roleName
        navItems.map(x => {
          if (x.roles && x.roles.length > 0 && x.roles.findIndex(y => y === role) > -1 || x.roles.length === 0) {
            this.navs.push(x)
          }
        })
      }
    }))
  }

  goto(url: string): void {
    this.store.dispatch(new Navigate([`/${url}`]))
    this.close.emit();
  }

  ngOnDestroy() {
    this.subs.unsubscribe()
    this.ngUnsubscribe.next()
    this.ngUnsubscribe.complete()
  }
  trackByFn(i, item) {
    return item.id
  }

  onLogout(): void {
    this.apiClient.auth_Logout()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (data) => {
          this.store.dispatch(new ResetUser());
          this.close.emit();
        },
        (error) => {
          if (isExpectedError(error)) {
            this.notify.error(error.message);
          } else {
            this.notify.error(errorMessages.serverRequestError);
          }
          console.error(error);
        },
      );
  }

}
