import { Injectable } from '@angular/core';
import { State, Selector, Action, StateContext } from '@ngxs/store'
import { Navigate } from '@ngxs/router-plugin'
import {IDataSelectedHelper} from "../../core/models/common.models";
import {
  SetUserRoles,
  SetTimeZones,
  SetCountries,
  SetCurrencies,
  SetServicePoints,
  SetServices,
  SetAvailableUsers,
  ResetDictionaries, GetAvailableData
} from "../actions/dictionary.actions";
import {ApiClient, IMtsService} from "../../app/ApiClient";
import {RolePipe} from "../../core/pipes/role.pipe";
import {getUserRoleRu, isExpectedError} from "../../core/helpers/data.helper";
import {ResetUser} from "../actions/user.actions";
import {errorMessages} from "../../constants/errors";
import {NotifyService} from "../../services/notify.service";

export class Dictionary {
  userRoles: IDataSelectedHelper[];
  timeZones: IDataSelectedHelper[];
  countries: IDataSelectedHelper[];
  currencies: IDataSelectedHelper[];
  servicePoints: IDataSelectedHelper[];
  services: IMtsService[];
  usersAvailable: IDataSelectedHelper[];
}

let token = ''
const defaults = {
  userRoles: [],
  timeZones: [],
  countries: [],
  currencies: [],
  servicePoints: [],
  services: [],
  usersAvailable: []
}

@State<Dictionary>({
  name: 'dictionary',
  defaults
})
@Injectable()
export class DictionaryState {

  constructor(
    private apiClient: ApiClient,
    private notify: NotifyService
  ) { }

  @Selector()
  static getUserRoles(dictionary: Dictionary): IDataSelectedHelper[] {
    return !!dictionary && dictionary.userRoles;
  }

  @Selector()
  static getTimeZones(dictionary: Dictionary): IDataSelectedHelper[] {
    return !!dictionary && dictionary.timeZones;
  }

  @Selector()
  static getCountries(dictionary: Dictionary): IDataSelectedHelper[] {
    return !!dictionary && dictionary.countries;
  }

  @Selector()
  static getCurrencies(dictionary: Dictionary): IDataSelectedHelper[] {
    return !!dictionary && dictionary.currencies;
  }

  @Selector()
  static getServicePoints(dictionary: Dictionary): IDataSelectedHelper[] {
    return !!dictionary && dictionary.servicePoints;
  }

  @Selector()
  static getServices(dictionary: Dictionary): IMtsService[] {
    return !!dictionary && dictionary.services;
  }

  @Selector()
  static getAvailableUsers(dictionary: Dictionary): IMtsService[] {
    return !!dictionary && dictionary.usersAvailable;
  }

  @Action(SetUserRoles)
  SetUserRoles({ patchState, dispatch }: StateContext<Dictionary>, { data }) {
    const temp = data as IDataSelectedHelper[];
    if (!!temp) {
      temp.map(x => {
        x.role = x.label;
        x.label = getUserRoleRu(x.label);
      })
    }
    patchState({ userRoles: temp });
  }

  @Action(SetTimeZones)
  SetTimeZones({ patchState, dispatch }: StateContext<Dictionary>, { data }) {
    patchState({ timeZones: data as IDataSelectedHelper[] });
  }

  @Action(SetCountries)
  SetCountries({ patchState, dispatch }: StateContext<Dictionary>, { data }) {
    patchState({ countries: data as IDataSelectedHelper[] });
  }

  @Action(SetCurrencies)
  SetCurrencies({ patchState, dispatch }: StateContext<Dictionary>, { data }) {
    patchState({ currencies: data as IDataSelectedHelper[] });
  }

  @Action(SetServicePoints)
  SetServicePoints({ patchState, dispatch }: StateContext<Dictionary>, { data }) {
    patchState({ servicePoints: data as IDataSelectedHelper[] });
  }

  @Action(SetServices)
  SetServices({ patchState, dispatch }: StateContext<Dictionary>, { data }) {
    patchState({ services: data });
  }

  @Action(SetAvailableUsers)
  SetAvailableUsers({ patchState, dispatch }: StateContext<Dictionary>, { data }) {
    patchState({ usersAvailable: data });
  }

  @Action(GetAvailableData)
  GetAvailableData({ patchState, dispatch }: StateContext<Dictionary>, { data }) {
    this.apiClient.users_GetAvailableData(data)
      .subscribe(
        (data) => {
          patchState({ usersAvailable: data as IDataSelectedHelper[] });
        },
        (error) => {
          if (isExpectedError(error)) {
            this.notify.error(error.message);
          } else {
            this.notify.error(errorMessages.serverRequestError);
          }
          console.error(error);
        },
      );
  }

  @Action(ResetDictionaries)
  ResetDictionaries({ setState, dispatch }: StateContext<Dictionary>) {
    setState(defaults);
  }
}
