<div class="main-head header-background">

  <h1 class="main-head-date">
    Сегодня, {{ today | date:'d MMMM yyy' }}
  </h1>

  <section class="main-head-stats">

    <content class="main-head-stats-list">

      <div class="main-head-stats-wrapper">
        <div class="main-head-stats-item-wrapper">
          <div class="main-head-stats-item">
            <div class="main-head-stats-img-wrapper">
              <img src="../../../../assets/img/icons/3-spin-dots.svg">
            </div>
            <p class="main-head-stats-title">Оборот по моновалютным операциям</p>
            <p class="main-head-stats-value">{{10000000 | number: "1.2":"ru"}}</p>
          </div>
        </div>

        <div class="main-head-stats-item-wrapper">
          <div class="main-head-stats-item separator">
            <div class="main-head-stats-img-wrapper">
              <img src="../../../../assets/img/icons/3-spin-dots.svg">
            </div>
            <p class="main-head-stats-title">Оборот по моновалютным операциям</p>
            <p class="main-head-stats-value">{{10000000 | number: "1.2":"ru"}}</p>
          </div>
        </div>
        <div class="main-head-stats-item-wrapper">
          <div class="main-head-stats-item separator">
            <div class="main-head-stats-img-wrapper">
              <img src="../../../../assets/img/icons/3-spin-dots.svg">
            </div>
            <p class="main-head-stats-title">Оборот по моновалютным операциям</p>
            <p class="main-head-stats-value">{{10000000 | number: "1.2":"ru"}}</p>
          </div>
        </div>
        <div class="main-head-stats-item-wrapper">
          <div class="main-head-stats-item separator">
            <div class="main-head-stats-img-wrapper">
              <img src="../../../../assets/img/icons/3-spin-dots.svg">
            </div>
            <p class="main-head-stats-title">Оборот по моновалютным операциям</p>
            <p class="main-head-stats-value">{{10000000 | number: "1.2":"ru"}}</p>
          </div>
        </div>
      </div>




     <!-- <article class="main-head-stats-item"
               *ngFor="let item of statItems; trackBy:trackByFn; let i=index"
      >
        <img [style.height.px]="50"
             [src]="'/assets/img/icons/' + item.ico"
             [alt]="item.name"
             class="main-head-stats-ico"
        >
        <p class="main-head-stats-title"
           [ngClass]="{'stats-1': i === 0, 'stats-2': i === 1, 'stats-3': i === 2, 'stats-4': i === 3}"
        >{{ item.name }}</p>
        <h2 class="main-head-stats-val" *ngIf="item.type === 'currency'">
          {{ item.amount.toFixed(2) || 0 | number: "1.2":"ru" }} <span class="roubleSign roubleSign--bold"></span>
        </h2>
        <h2 class="main-head-stats-val" *ngIf="item.type === 'number'">
          {{ item.amount || 0 }}
        </h2>
        <ng-container *ngIf="item.type === 'balance'">
          <h2 class="main-head-stats-val">
            {{ balanceAmount.toFixed(2) || 0 | number: "1.2":"ru" }} <span class="roubleSign roubleSign--bold" *ngIf="isRoubleBalance"></span> <span *ngIf="!isRoubleBalance">{{ balance.balanceCurrency }}</span>
          </h2>
        </ng-container>
      </article>-->
    </content>
  </section>
</div>
<!--<div class="head-oper-time" *ngIf="((isAdminNabix$ | async) || ((isSuperAdminNabix$ | async))) && !!lastOperation">Последние действия по операциям {{lastOperation | date: 'dd.MM.yyyy'}} в {{lastOperation | date: 'HH:mm'}} МСК</div>-->
