import {UserLoginRequest, IUserResponse, TokenResponse} from '../../app/ApiClient';
export class LoginRedirect {
  static type = '[User] LoginRedirect'
}

export class SetAgent {
  static type = '[User] SetAgent'
  constructor(public data: string) {}
}


export class SetToken {
  static type = '[User] SetToken'
  constructor(public data: TokenResponse) {}
}

export class SetUser {
  static type = '[User] SetUser';
  constructor(public user: IUserResponse) {}
}

export class ResetUser {
  static type = '[User] ResetUser';
  constructor() {}
}

export class HomeRedirect {
  static type = '[User] HomeRedirect';
  constructor() {}
}
